import { actions } from './partner_clinic/actions'
import { getters } from './partner_clinic/getters'
import { state } from './partner_clinic/state'
import { mutations } from './partner_clinic/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}