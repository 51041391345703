import { actions } from './excelColumns/actions'
import { mutations } from './excelColumns/mutations'
import { getters } from './excelColumns/getters'
import { state } from './excelColumns/state'
export default {
  namespaced: true,
  actions,
  state,
  mutations,
  getters
}
