import { actions } from './state/actions'
import { getters } from './state/getters'
import { state } from './state/state'
import { mutations } from './state/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}
