<template>
  <el-select
    :value="selected"
    @input="dispatch"
    :placeholder="plc || $t('message.country')"
    filterable
    clearable
  >
    <el-option
      v-for="item in states"
      :key="item.id"
      :label="item.name"
      :value="item.id"
    ></el-option>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import inventory from "@/utils/mixins/components/inventory";

export default {
  mixins: [inventory],
  props: ["id", "state_id"],
  mounted() {
    if (this.states && this.states.length === 0) {
      this.getInventory();
    }
  },
  watch: {
    id: {
      handler: function () {
        setTimeout(() => {
          this.selected = this.id;
        });
      },
      deep: true,
      immediate: true,
    },
    state_id: function (val, oldVal) {
      this.selected = val;
      this.$emit("input", this.selected);
    },
  },
  computed: {
    ...mapGetters({
      states: "states/inventory",
    }),
  },
  methods: {
    ...mapActions({
      getInventory: "states/inventory",
    }),
  },
};
</script>
