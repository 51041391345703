import request from '../utils/request';

export function index(params) {
  return request({
    url: '/users',
    method: 'get',
    params,
  });
}

export function free_owner_branch(params) {
  return request({
    url: '/users/free_owner_branch',
    method: 'get',
    params,
  });
}

export function search(data) {
  return request({
    url: '/users',
    method: 'post',
    params,
  });
}

export function show(id) {
  return request({
    url: `/users/${id}`,
    method: 'get',
  });
}

export function restore(data) {
  return request({
    url: `/user/restore`,
    method: 'post',
    data,
  });
}

export function code(data) {
  return request({
    url: `/user/code`,
    method: 'post',
    data,
  });
}

export function resetPassword(data) {
  return request({
    url: `/user/reset-password`,
    method: 'post',
    data,
  });
}

export function store(data) {
  return request({
    url: '/users',
    method: 'post',
    data,
  });
}

export function update(params) {
  return request({
    url: `/users/${params.id}`,
    method: 'post',
    data: params.data,
  });
}

export function destroy(id) {
  return request({
    url: `/users/${id}`,
    method: 'delete',
  });
}

export function inventory() {
  return request({
    url: '/users/inventory',
    method: 'GET'
  });
}

export function inventory_general() {
  return request({
    url: '/users/inventory_general',
    method: 'GET'
  });
}

export function doctor_list(params) {
  return request({
    url: `/users/doctor_list`,
    method: 'GET',
    params
  });
}
