<template>
  <el-select
    v-loading="loadingData"
    :value="selected"
    @input="dispatch"
    :placeholder="plc || $t('message.patient')"
    filterable
    clearable
    :size="size"
    class="d-block"
    remote
    :remote-method="searchPatientWithLoad"
  >
    <el-option
      v-for="(patient, index) in patients"
      :key="'patients-' + index"
      :label="patient.surname + ' ' + patient.first_name"
      :value="patient.id"
    ></el-option>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import mix from "@/utils/mixins/components/inventory";

export default {
  name: "patients",
  mixins: [mix],
  props: ["patient_id"],
  data() {
    return {
      loadingData: false,
    };
  },
  watch: {
    patient_id: function (val, oldVal) {
      this.selected = val;
      this.$emit("input", val);
    },
  },
  mounted() {
    if (this.patients && this.patients.length === 0)
      this.updateInventory({ per_page: 30 });
  },
  computed: {
    ...mapGetters({
      patients: "hospitalPatients/inventory",
    }),
  },
  methods: {
    ...mapActions({
      updateInventory: "hospitalPatients/inventory",
    }),
    searchPatientWithLoad(val) {
      this.searchPatient(val);
    },

    searchPatient: _.debounce(function (val) {
      this.loadingData = true;
      this.updateInventory({ search_patient: val, per_page: 30 }).finally(
        () => {
          this.loadingData = false;
        }
      );
    }, 500),
  },
};
</script>