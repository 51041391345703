import Layout from '@/layouts'

const categoryService = {
  path: '/categoryServices',
  component: Layout,
  name: 'categoryServices',
  redirect: '/categoryServices/index',
  children: [
    {
      path: '/categoryServices/index',
      component: () => import('@/views/categoryService/index'),
      name: 'categoryServices.index',
      meta: { title: 'Категория услуги'}
    },
  ]
}

export default categoryService
