<template>
  <el-select
    :value="selected"
    @input="dispatch"
    :placeholder="plc || $t('message.user')"
    filterable
    clearable
    :size="size"
    class="d-block"
    :multiple="multiple"
    :own_id="own_id"
  >
    <el-option
      v-for="user in filtered"
      :key="user.id"
      :label="
        (user.surname ? user.surname : '') +
        ' ' +
        (user.name ? user.name : '') +
        ' ' +
        (user.patronymic ? user.patronymic : '')
      "
      :value="user.id"
    ></el-option>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import inventory from "@/utils/mixins/components/inventory";

export default {
  mixins: [inventory],
  props: {
    selected_users: {
      type: [Array, String, Object],
      default: null,
    },
    user_id: {
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    own_id: {
      default: null,
    },
    doctor: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    user_id: {
      handler: function () {
        this.dispatch(this.user_id);
      },
      deep: true,
      immediate: true,
    },
    selected_users: {
      handler: function () {
        this.dispatch(this.selected_users);
      },
      deep: true,
      immediate: true,
    },
    doctor: {
      handler: function () {
        this.dispatch(this.doctor);
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    let query = { doctor: this.doctor };
    if (this.users && this.users.length === 0) {
      this.getInventory(query);
    }
  },
  computed: {
    ...mapGetters({
      users: "users/list",
    }),
    filtered: function () {
      if (this.own_id) {
        return this.users.filter((o) => {
          return this.own_id !== o.id;
        });
      } else {
        return this.users;
      }
    },
  },
  methods: {
    ...mapActions({
      getInventory: "users/index",
    }),
  },
};
</script>