import request from '../utils/request';

export function index(params) {
  return request({
    url: '/warehouseProducts',
    method: 'GET',
    params
  });
}

export function show(params) {
  return request({
    url: `/warehouseProducts/${params.getId}`,
    method: 'get',
    params
  })
}

export function acceptProd(data) {
  return request({
    url: `/warehouseProduct/acceptProd`,
    method: 'POST',
    data
  })
}

export function reportProduct(data) {
  return request({
    url: '/warehouseProduct/reportProduct',
    method: 'post',
    data
  });
}
