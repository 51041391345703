import { i18n } from '@/utils/i18n';
export const columns = {
    id: {
        show: true,
        title: "№",
        sortable: true,
        column: 'id'
    },
    name: {
        show: true,
        title: i18n.t('message.short_name'),
        sortable: true,
        column: 'name'
    },
    full_name: {
        show: true,
        title: i18n.t('message.full_name'),
        sortable: true,
        column: "full_name"
    },
    code: {
        show: true,
        title: i18n.t('message.code'),
        sortable: true,
        column: "code"
    },
    status: {
        show: false,
        title: i18n.t('message.status_0'),
        sortable: true,
        column: "status"
    },
    created_at: {
        show: true,
        title: i18n.t('message.date'),
        sortable: true,
        column: 'created_at'
    },
    updated_at: {
        show: false,
        title: i18n.t('message.update_date'),
        sortable: false,
        column: 'updated_at'
    },
    settings: {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    }
};