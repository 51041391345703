import { i18n } from '@/utils/i18n';
export const columns = {
  id: {
    show: true,
    title: '№',
    sortable: true,
    column: 'id'
  },
  date: {
    show: true,
    title: i18n.t('message.all_date'),
    sortable: true,
    column: 'date'
  },
  supplier_id: {
    show: true,
    title: i18n.t('message.suppliers'),
    sortable: true,
    column: 'supplier_id'
  },
  supplier_contract_id: {
    show: true,
    title: i18n.t('message.suppliers_aggrement'),
    sortable: true,
    column: 'supplier_contract_id'
  },
  delivery_deadline: {
    show: true,
    title: i18n.t('message.delivery_deadline'),
    sortable: true,
    column: 'delivery_deadline'
  },
  total_cost: {
    show: true,
    title: i18n.t('message.total_cost'),
    sortable: true,
    column: 'total_cost'
  },
  status_id: {
    show: true,
    title: i18n.t('message.status'),
    sortable: true,
    column: 'status_id'
  },
  description: {
    show: true,
    title: i18n.t('message.description'),
    sortable: true,
    column: 'description'
  },
  receive: {
    show: true,
    title: i18n.t('message.receive'),
    sortable: false,
    column: 'receive'
  },
  created_at: {
    show: true,
    title: i18n.t('message.date'),
    sortable: true,
    column: 'created_at'
  },
  updated_at: {
    show: true,
    title: i18n.t('message.update_date'),
    sortable: true,
    column: 'updated_at'
  },
  settings: {
    show: true,
    title: i18n.t('message.settings'),
    sortable: false,
    column: 'settings'
  }
};