import { actions } from './contracts/actions'
import { getters } from './contracts/getters'
import { state } from './contracts/state'
import { mutations } from './contracts/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}
