import {
  index,
  show,
  store,
  update,
  destroy,
  myPatinets,
  lastIdPatent,
  search,
  inventory,
  getLastId,
  loadPatientLastOrderServices
} from "@/api/patients";

export const actions = {
  index({commit}, params = {}) {
    return new Promise((resolve, reject) => {
      index(params)
        .then((res) => {
          commit("SET_LIST", res.data.data.patients.data);
          commit("UPDATE_PAGINATION", {
            key: "total",
            value: res.data.data.patients.pagination.total,
          });
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  getLastId(params) {
    return new Promise((resolve, reject) => {
      getLastId()
        .then(res => {
          params.commit('SET_LAST_ID', res.data.data.last_id)
          resolve(res.data)
        })
        .catch(err => {
          reject(err.response.data)
        })
    });
  },
  inventory({
    commit
  }, params = {}) {
    return new Promise((resolve, reject) => {
      inventory(params)
        .then((res) => {
          commit("SET_INVENTORY", res.data.data.patients.data);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  myPatinets({
    commit
  }, params = {}) {
    return new Promise((resolve, reject) => {
      myPatinets(params)
        .then((res) => {
          commit("SET_PATINETS_LIST", res.data.data.patients);
          commit("UPDATE_PAGINATION", {
            key: "total",
            value: res.data.data.pagination.total,
          });
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  },

  show({
    commit
  }, id) {
    return new Promise((resolve, reject) => {
      show(id)
        .then((res) => {
          commit("SET_MODEL", res.data.data.patient);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  },

  lastIdPatent({
    commit
  }, params = {}) {
    return new Promise((resolve, reject) => {
      lastIdPatent(params)
        .then((res) => {
          commit("SET_LAST_MODEL_ID", res.data.data);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  },

  store({
    commit
  }, data) {
    return new Promise((resolve, reject) => {
      store(data)
        .then((res) => {
          commit("SET_MODEL", res.data.data.patient);
          commit("INCREMENT_LAST_ID");
          resolve(res.data);
        })
        .catch((err) => {
          typeof err.response != "undefined" ?
            reject(err.response.data) :
            reject(err);
        });
    });
  },

  update({
    commit
  }, data) {
    return new Promise((resolve, reject) => {
      update(data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  },

  destroy({
    commit
  }, id) {
    return new Promise((resolve, reject) => {
      destroy(id)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  },
  loadPatientLastOrderServices({
    commit
  }, id) {
    return new Promise((resolve, reject) => {
      loadPatientLastOrderServices(id)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  },

  updateSort({
    commit
  }, sort) {
    commit("SET_SORT", sort);
  },

  updateFilter({
    commit
  }, filter) {
    commit("SET_FILTER", JSON.parse(JSON.stringify(filter)));
  },

  updateColumn({
    commit
  }, obj) {
    commit("UPDATE_COLUMN", obj);
  },
  updatePagination({
    commit
  }, pagination) {
    commit("UPDATE_PAGINATION", pagination);
  },

  refreshData({
    commit
  }) {
    return new Promise((resolve, reject) => {
      commit("REFRESH");
      resolve();
    });
  },
  empty({
    commit
  }) {
    return new Promise((resolve, reject) => {
      commit("EMPTY_MODEL");
      resolve();
    });
  },

  search({
    commit
  }, params = {}) {
    return new Promise((resolve, reject) => {
      search(params)
        .then((res) => {
          if (res.data.data.patients)
            commit("SET_SEARCH_LIST", res.data.data.patients);
          else commit("SET_SEARCH_LIST", []);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  },

  emptySearch({
    commit
  }) {
    commit("EMPTY_SEARCH");
  },

};