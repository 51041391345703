import { actions } from './role/actions'
import { mutations } from './role/mutations'
import { getters } from './role/getters'
import { state } from './role/state'
export default {
  namespaced: true,
  actions,
  state,
  mutations,
  getters
}
