export const filter = {
    id: "",
    warehouse_id: "",
    product_id: "",
    remainder: "",
    recived: "",
    recived_date: "",
    search: "",
    created_at: "",
    updated_at: "",
};
