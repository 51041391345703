import request from '../utils/request'


export function index(params) {
  return request({
    url: '/hospitalizationFinalConclusion/index',
    method: 'get',
    params
  })
}
export function destroy() {
  return request({
    url: `/hospitalizationFinalConclusion/destroy`,
    method: 'delete',
  })
}
