import { i18n } from '@/utils/i18n';

export const columns = {
    id: {
        show: true,
        title: "№",
        sortable: true,
        column: 'id'
    },
    name: {
        show: true,
        title: i18n.t('message.name'),
        sortable: true,
        column: 'name'
    },
    mfo: {
        show: true,
        title: i18n.t('message.mfo'),
        sortable: true,
        column: 'mfo'
    },
    currency_id: {
        show: true,
        title: i18n.t('message.currency'),
        sortable: true,
        column: 'currency_id'
    },
    number: {
        show: true,
        title: i18n.t('message.number'),
        sortable: true,
        column: 'number'
    },
    balance: {
        show: true,
        title: i18n.t('message.balance'),
        sortable: true,
        column: 'balance'
    },
    outgoing: {
        show: true,
        title: i18n.t('message.outgoing'),
        sortable: true,
        column: 'outgoing'
    },
    incoming: {
        show: true,
        title: i18n.t('message.incoming'),
        sortable: true,
        column: 'incoming'
    },
    created_at: {
        show: true,
        title: i18n.t('message.date'),
        sortable: true,
        column: 'created_at'
    },
    updated_at: {
        show: false,
        title: i18n.t('message.update_date'),
        sortable: true,
        column: 'updated_at'
    },
    settings: {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    }
};
