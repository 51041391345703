import request from '../utils/request'


export function index(params) {
  return request({
    url: '/invoiceInsurances',
    method: 'get',
    params
  })
}

export function pay(data) {
  return request({
    url: `/invoiceInsurance/pay/${data.id}`,
    method: 'put',
    data
  })
}

export function excel(params) {
  return request({
    url: "/invoiceInsurance/excel",
    method: "get",
    responseType: 'blob',
    params
  });
}

export function show(id) {
  return request({
    url: `/invoiceInsurances/${id}`,
    method: 'get'
  })
}

export function store(data) {
  return request({
    url: '/invoiceInsurances',
    method: 'post',
    data
  })
}

export function update(data) {
  return request({
    url: `/invoiceInsurances/${data.id}`,
    method: 'put',
    data
  })
}

export function destroy(id) {
  return request({
    url: `/invoiceInsurances/${id}`,
    method: 'delete',
  })
}
