export const rules = {
  name: [
    { required: true, message: 'Пожалуйста укажите наименование', trigger: 'blur' },
  ],
  price: [
    { required: true, message: 'Пожалуйста укажите стоимость', trigger: 'blur' },
  ],
  category_id: [
    { required: true, message: 'Пожалуйста укажите группу', trigger: 'blur' },
  ],
  measurement_id: [
    { required: true, message: 'Пожалуйста укажите единицу измерения', trigger: 'blur' },
  ],
};