export const model = {
    id: null,
    name      : '',
    surname   : '',
    patronymic: '',
    email     : '',
    phone     : '',
    phone_two : '',
    role_id   : null,
    role_name : '',
    gender    : '',
    id_code   : '',
    image     : '',
    locked    : '',
    last_login: '',
    created_at: '',
    updated_at: '',
    room_id   : null,
    specialties   : [],
    doctor_services   : []
};