import { actions } from './patient_age_report/actions'
import { getters } from './patient_age_report/getters'
import { state } from './patient_age_report/state'
import { mutations } from './patient_age_report/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}