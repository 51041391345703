// Here is a list of the toolbar
// Detail list see https://www.tinymce.com/docs/advanced/editor-control-identifiers/#toolbarcontrols

const toolbar = 
[
    'searchreplace bold italic underline strikethrough alignleft aligncenter alignright outdent indent  blockquote undo redo removeformat subscript superscript code codesample ', 
    'hr bullist numlist lineheightselect link image charmap preview anchor pagebreak insertdatetime media table emoticons forecolor backcolor fullscreen'
]

export default toolbar
