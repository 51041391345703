import { i18n } from '@/utils/i18n';

export const columns = {
    id: {
        show: true,
        title: "№",
        sortable: true,
        column: 'id'
    },
    warehouse_id: {
        show: true,
        title:   i18n.t('message.warehouse'),
        sortable: true,
        column: 'warehouse_id'
    },
    available: {
        show: true,
        title:   i18n.t('message.available'),
        sortable: true,
        column: 'available'
    },
    day_in_warehouse: {
        show: true,
        title: i18n.t('message.day_in_warehouse'),
        sortable: true,
        column: 'day_in_warehouse'
    }, 
    rate: {
        show: true,
        title: i18n.t('message.rate'),
        sortable: true,
        column: 'rate'
    },
    price: {
        show: true,
        title: i18n.t('message.new_price'),
        sortable: true,
        column: 'price'
    },
    price_all: {
        show: true,
        title: i18n.t('message.price2'),
        sortable: true,
        column: 'price'
    },
    new_price: {
        show: true,
        title: i18n.t('message.new_price'),
        sortable: true,
        column: 'new_price'
    },
    new_price_all: {
        show: true,
        title:  i18n.t('message.sale_price'),
        sortable: true,
        column: 'new_price_all'
    },
    percent: {
        show: true,
        title: i18n.t('message.percent'),
        sortable: true,
        column: 'percent'
    },
    manufactured_date: {
        show: true,
        title: i18n.t('message.manufactured_date'),
        sortable: true,
        column: 'manufactured_date'
    },
    // created_at: {
    //     show: true,
    //     title: "Создан в",
    //     sortable: true,
    //     column: 'created_at'
    // },
    // updated_at: {
    //     show: false,
    //     title: "Изменено",
    //     sortable: true,
    //     column: 'updated_at'
    // },
    settings: {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    }
};
