import { index, show, store, update, destroy} from '../../../api/dailyProcessReport';

export const actions = {

    index({commit}, params = {}) {
        return new Promise((resolve, reject) => {
            index(params).then(res => {
                commit("SET_LIST", res.data.data.daily_process_reports.data);
                // commit("SET_DATE_LIST", res.data.data.dates);
                commit("UPDATE_PAGINATION",{key: 'total', value: res.data.data.daily_process_reports.pagination.total});
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },
 
    show({commit},id) {
        return new Promise((resolve,reject) => {
            show(id).then(res => {
                commit("SET_MODEL",res.data.data);
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    store({commit},data) {
        return new Promise((resolve,reject) => {
            store(data).then(res => {
               
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    update({commit},data) {
        return new Promise((resolve,reject) => {
            update(data).then(res => {
                commit("SET_MODEL",res.data.data.dailyProcessReport);
                resolve(res.data)
            }).catch(err => {
                commit("SET_ERROR", err.response.data.error.errormessage);
                reject(err.response.data)
            })
        })
    },

    destroy({commit},id) {
        return new Promise((resolve,reject) => {
            destroy(id).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    updateSort({commit}, sort){
        commit("SET_SORT",sort)
    },

    updateFilter({commit}, filter){
        commit('SET_FILTER', JSON.parse( JSON.stringify( filter)))
    },

    updateColumn({commit}, obj){
        commit('UPDATE_COLUMN', obj)
    },

    updatePagination({commit}, pagination){
        commit('UPDATE_PAGINATION', pagination )
    },

    refreshData({commit}){
        return new Promise((resolve, reject) => {
            commit("REFRESH");
            resolve()
        })
    },
    empty({ commit }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_MODEL");
            resolve()
        })
    },


}
