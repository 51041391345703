import { actions } from './cost/actions'
import { getters } from './cost/getters'
import { state } from './cost/state'
import { mutations } from './cost/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}
