import { index,show } from '../../../api/partnerDoctorReport';

export const actions = {

    index({commit}, params = {}) {
        return new Promise((resolve, reject) => {
            index(params).then(res => {
                commit("SET_LIST", res.data.data.data);
                commit("UPDATE_PAGINATION", {key: 'total', value: res.data.data.total});
                resolve(res.data)
            }).catch(err => {
                    reject(err.response.data)
            })
        })
    },
    show({ commit }, id) {
        return new Promise((resolve, reject) => {
            show(id)
                .then(res => {
                    commit('SET_MODEL', res.data.data.data.doctors);
                    commit('SET_REPORTS', res.data.data.reports);
                    commit("UPDATE_SHOW_PAGINATION", {key: 'total', value: res.data.data.data.pagination.total});
                    resolve(res.data.data);
                })
                .catch(err => {
                    reject(err.response.data);
                });
        });
    },

    inventory({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
          inventory(params)
            .then(response => {
              commit('SET_INVENTORY', response.data.data.warehouses.data);
              resolve(response.data);
            })
            .catch(error => {
              reject(error.response.data);
            });
        });
      },

    selectedPartnerDoctorReport({commit}, model){
        commit("SELECTED_PARTNER_DOCTOR_REPORT",model)
    },

    updateSort({commit}, sort){
        commit("SET_SORT",sort)
    },

    updateFilter({commit}, filter){
        commit('SET_FILTER', JSON.parse( JSON.stringify( filter)))
    },

    updateColumn({commit}, obj){
        commit('UPDATE_COLUMN', obj)
    },

    updatePagination({commit}, pagination){
        commit('UPDATE_PAGINATION', pagination )
    },

    updateShowPagination({commit}, pagination){
        commit('UPDATE_SHOW_PAGINATION', pagination )
    },  

    refreshData({commit}){
        return new Promise((resolve, reject) => {
            commit("REFRESH");
        resolve()
    })
    },
    empty({ commit }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_MODEL");
        resolve()
    })
    },


}
