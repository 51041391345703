import { model } from "./properties/model";
import { i18n } from '@/utils/i18n';

export const mutations = {
    SET_MODEL: (state, model) => {
        state.model = model;
    },
    SET_CONCLUSION_LOGO_MODEL: (state, model) => {
        state.conclusion_logo_model = model;
    },
    SET_PHONE_NUMBERS: (state, model) => {
        state.phone_numbers = model;
    },
    EMPTY_MODEL: (state) => {
        state.model = JSON.parse(JSON.stringify(model));
    },
    REFRESH: (state) => {
        state.filter = JSON.parse(JSON.stringify(filter));
        state.sort = JSON.parse(JSON.stringify(sort));
        state.pagination = JSON.parse(JSON.stringify(pagination));
    },
    UPDATE_LANG_COLUMN: (state) => {
        state.columns.id.title = "№";
        state.columns.name.title = i18n.t('message.name');
        state.columns.surname.title = i18n.t('message.surname');
        state.columns.patronymic.title = i18n.t('message.patronymic');
        state.columns.note.title = i18n.t('message.notes');
        state.columns.id_code.title = i18n.t('message.code_id');
        state.columns.created_at.title = i18n.t('message.date');
        state.columns.updated_at.title = i18n.t('message.update_date');
        state.columns.settings.title = i18n.t('message.settings');
    }
};
