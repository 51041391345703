import { actions } from './columns/actions'
import { getters } from './columns/getters'
import { state } from './columns/state'
import { mutations } from './columns/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
};
