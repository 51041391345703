import request from './../utils/request';

export function index(params) {
  return request({
    url: '/remainders',
    method: 'GET',
    params
  });
}

export function show(id) {
  return request({
    url: '/remainders/' + id,
    method: 'GET',
  });
}