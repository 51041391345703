<template>
  <el-select
    :value="selected"
    @input="dispatch"
    :placeholder="plc || 'Пользователи'"
    filterable
    clearable
  >
    <el-option v-for="item in users" :key="item.id" :label="item.name +' '+ item.surname +' '+ (item.patronymic? item.patronymic: '') "  :value="item.id"></el-option>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import inventory from "@/utils/mixins/components/inventory";

export default {
  mixins: [inventory],
  props: ["user_id", 'doctor_id'],
  mounted() {
    if (this.users && this.users.length === 0) {
      this.getInventory();
    }
  },
  watch: {
    user_id: {
      handler: function(val, oldVal) {
        this.selected = val;
        this.$emit("input", this.selected);
      },
      deep: true,
      immediate: true
    },
    doctor_id: {
      handler: function(val, oldVal) {
        this.selected = val;
        this.$emit("input", this.selected);
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapGetters({
      users: "users/inventory"
    })
  },
  methods: {
    ...mapActions({
      getInventory: "users/inventory"
    })
  }
};
</script>
