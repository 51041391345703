import { actions } from './branch/actions'
import { getters } from './branch/getters'
import { state } from './branch/state'
import { mutations } from './branch/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}
