import { index, show, store, update, destroy } from "../../../api/lang"

export const actions = {
  store({ commit }, data) {
    return new Promise((resolve, reject) => {
      store(data)
        .then((res) => {
          //   commit("CREATE_MODEL", res.data.data.region)
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
}
