export const model = {
    id: null,
    patient_id: "",
    patient: {},
    day: "",
    notes: "",
    condition_id: "",
    partner_doctor_id: "",
    partner_clinic_id: null,
    yes_doctor: "",
    doctor_id: "",
    from_hour: "",
    duration: "",  
    services: "", 
    specialties: "", 
    status: '',
    created_at: '',
    updated_at: '',
    outpatient_card: '',
    payment_status_free: false
};
