import request from './../utils/request'

export function patientMedicineShow(data) {
    return request({
        url: '/order-patient/patient-medicine-show',
        method: 'get',
        data
    })
}

export function patientShow(id) {
    return request({
        url: `/order-patient/patient-medicine-show/${id}`,
        method: 'get',
    })
}


export function patientMedicineUpdate(data) {
    return request({
      url: `/orders/patient-medicine/${data.id}`,
      method: 'put',
      data
    })
  }

export function patientMedicineShowOrder(id) {
    return request({
        url: `/order-patient/patient-medicine-show-order/${id}`,
        method: 'get',
    })
}


export function patientListMedicine(params) {
    return request({
        url: '/orders-patient/patient-list-medicine',
        method: 'get',
        params
    })
}