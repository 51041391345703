var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex"},[(
      _vm.permissions.some((per) => per.slug == _vm.name + '.' + 'show') ? true : true
    )?_c('el-button',{staticStyle:{"background-color":"#1890ff","border-color":"#1890ff"},attrs:{"icon":"el-icon-edit el-icon--left","round":""},on:{"click":function($event){return _vm.handleCommand('edit')}}}):_vm._e(),(
      _vm.permissions.some((per) => per.slug == _vm.name + '.' + 'delete')
        ? true
        : true
    )?_c('el-button',{staticStyle:{"background-color":"#ff4b55","border-color":"#ff4b55"},attrs:{"icon":"el-icon-delete el-icon--left","round":""},on:{"click":function($event){return _vm.handleCommand('delete')}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }