import Layout from '@/layouts'

const paymentType = {
  path: '/warehouseProvider',
  component: Layout,
  name: 'warehouseProvider',
  redirect: '/warehouseProvider/index',
  children: [{
    path: '/warehouseProvider/index',
    component: () => import('@/views/warehouseProvider/index'),
    name: 'warehouseProvider.index',
    meta: {
      title: 'Продукты'
    }
  }, ]
}

export default paymentType