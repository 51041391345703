import { actions } from './physiotherapy/actions'
import { getters } from './physiotherapy/getters'
import { state } from './physiotherapy/state'
import { mutations } from './physiotherapy/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}
