export const filter = {
    id: "",
    company_name: "",
    phone: "",
    code: "",
    fax: "",
    email: "",
    address: "",
    inn: "",
    mfo: "",
    okonx: "",
    oked: "",
    rkp_nds: "",
    search: "",
    created_at: "",
    updated_at: "",
};
