import { index, show, indexitems, store, update, destroy, inventory, destroyItem, getservices } from '../../../api/hospitalizationService';

export const actions = {
  index({ commit }, params) {
    return new Promise((resolve, reject) => {
      index(params)
        .then(response => {
          commit('SET_LIST', response.data.data.hospitalization_services.data);
          commit('UPDATE_PAGINATION', {
            key: 'total',
            value: response.data.data.hospitalization_services.total
          });
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  inventory({ commit }, params = {}) {
    return new Promise((resolve, reject) => {
      inventory(params)
        .then(response => {
          commit('SET_INVENTORY', response.data.data.hospitalization_services.data);
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },    
  show({commit},id) {
    return new Promise((resolve,reject) => {
        show(id).then(res => {
            commit("SET_MODELL",res.data.data);
            resolve(res.data)
        }).catch(err => {
            reject(err.response.data)
        })
    })
  },
  getservices({commit},id) {
    return new Promise((resolve,reject) => {
      getservices(id).then(res => {
            commit("SET_SERVICES",res.data.data.hospitalization_services);
            resolve(res.data)
        }).catch(err => {
            reject(err.response.data)
        })
    })
  },
  indexitems({ commit }, params = {}) {
    return new Promise((resolve, reject) => {
      indexitems(params)
        .then(response => {
          commit("SET_LIST",response.data.data);
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  store({ commit }, data) {
    return new Promise((resolve, reject) => {
        store(data).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err.response.data)
        })
    })
  },

  update({ commit }, payload) {
    return new Promise((resolve, reject) => {
      update(payload)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  destroy({ commit }, id) {
  return new Promise((resolve, reject) => {
      destroy(id)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  destroyItem({ commit }, id) {
    return new Promise((resolve, reject) => {
      destroyItem(id)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  updateSort({ commit }, sort) {
    commit('SET_SORT', sort);
  },
  assign({ commit }, service) {
    commit('SET_SERVICE', service);
  },
  updateFilter({ commit }, filter) {
    commit('SET_FILTER', JSON.parse(JSON.stringify(filter)));
  },
  updateColumn({ commit }, column) {
    commit('SET_COLUMN', column);
  },
  updatePagination({ commit }, pagination) {
    commit('UPDATE_PAGINATION', pagination);
  },
  refreshData({ commit }) {
    return new Promise((resolve, reject) => {
      commit('REFRESH'); 
      resolve();
    });
  },
  empty({ commit }) {
    commit('EMPTY_MODEL');
  }
};