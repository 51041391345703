export const getters = {
    schedulePatients: state => state.schedulePatients,
    stasionaryPatients: state => state.stasionaryPatients,
    paymentsSum: state => state.paymentsSum,
    expences: state => state.expences,
    contractsCount: state => state.contractsCount,
    formTemplates: state => state.formTemplates,
    items: state => state.items,
    // payment_chart: state => state.payment_chart,
    // cost_chart: state => state.cost_chart,
    patientScheduleCount: state => state.patientScheduleCount,
    stasionaryPayments: state => state.stasionaryPayments,
    warehouseCount: state => state.warehouseCount,
    number_of_orders_today: state => state.number_of_orders_today,
    number_of_orders_month: state => state.number_of_orders_month,
    doctor: state => state.doctor,
    partner_doctor: state => state.partner_doctor,
    top_services: state => state.top_services,
    gender: state => state.gender,
    hospital: state => state.hospital,
    order_chart: state => state.order_chart,
    patient_chart: state => state.patient_chart,
    age: state => state.age,
    reportClinic: state => state.reportClinic,
    reportDoctor: state => state.reportDoctor,
};
