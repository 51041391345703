export const model = {
  id: null,
  name: '',
  full_name: '',
  code: '',
  phone: '',
  fax: '',
  email: '',
  description: '',
  actual_address: '',
  type: '',
  legal_address: '',
  inn: '',
  mfo: '',
  okonx: '',
  oked: '',
  rkp_nds: '',
  old_contacts: [],
  old_bank_accounts: [],
  bank_accounts: [],
  contacts: [],
};