export const getters = {
    list: state => state.list,
    events: state => state.events,
    dataroom: state => state.dataroom,
    bunk: state => state.bunk,
    inventory: state => state.inventory,
    columns: state => state.columns,
    model: state => state.model,
    filter: state => state.filter,
    pagination: state => state.pagination,
    sort: state => state.sort,
    rules: state => state.rules,
    saving: state => state.saving
};
