export const model = {
    id: null,
    service_id: "",
    doctor_id: "",
    patient_id: "",
    form_template_id: "",
    created_at: '',
    updated_at: '',
    order_services: ''
};
