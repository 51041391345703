import { actions } from './class/actions'
import { getters } from './class/getters'
import { state } from './class/state'
import { mutations } from './class/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}
