import { model } from "./properties/model";
import { filter } from "./properties/filter";
import { sort } from "../properties/sort";
import { pagination } from "../properties/pagination";
import { i18n } from '@/utils/i18n';

export const mutations = {
    SET_LIST: (state, permissions) => (state.list = permissions),
    SET_FILTER: (state,filter) => (state.filter = filter),
    SET_PAGINATION: (state,pagination) => (state.pagination = pagination),
    SET_SORT: (state,sort) => (state.sort = sort),
    SET_MODEL: (state,model) => {
        state.model.id = model.id;
        state.model.name = model.name;
        state.model.slug = model.slug;
        state.model.parent_id = model.parent_id;
        state.model.parent = model.children;
        state.model.created_at = model.created_at;
        state.model.updated_at = model.updated_at;
    },
    SET_PARENT_PERMISSION: (state,permissions) => {
      state.parent_permissions = permissions
    },
    UPDATE_COLUMN: (state, obj) => {
        state.columns[obj.key].show = obj.value
    },
    UPDATE_SORT: (state, sort) => {
        state.sort[sort.column] = sort.order
    },
    UPDATE_PAGINATION: (state, pagination) => {
        state.pagination[pagination.key] = pagination.value
    },
    EMPTY_MODEL: (state) => {
        state.model = JSON.parse( JSON.stringify( model ));
    },
    REFRESH: (state) => {
        state.filter = JSON.parse( JSON.stringify( filter ));
        state.sort = JSON.parse( JSON.stringify( sort ));
        state.pagination = JSON.parse( JSON.stringify( pagination ));
    },
    UPDATE_LANG_COLUMN: (state) => {
        state.columns = {
            id: {
                show: true,
                title: "№",
                sortable: true,
                column: 'id'
            },
            name: {
                show: true,
                title: i18n.t('message.name'),
                sortable: true,
                column: 'name'
            },
            parent_id: {
                show: true,
                title: i18n.t('message.group'),
                sortable: false,
                column: 'parent_id'
            },
            slug: {
                show: true,
                title:  i18n.t('message.slug'),
                sortable: false,
                column: 'slug'
            },
            created_at: {
                show: true,
                title: i18n.t('message.date'),
                sortable: true,
                column: 'created_at'
            },
            updated_at: {
                show: false,
                title: i18n.t('message.update_date'),
                sortable: true,
                column: 'updated_at'
            },
            settings: {
                show: true,
                title: i18n.t('message.settings'),
                sortable: false,
                column: 'settings'
            }
        };        
    }
};
