import Layout from "@/layouts";

const documentType = {
  path: "/transactionScores",
  component: Layout,
  name: "transactionScores",
  redirect: "/transactionScores/index",
  children: [
    {
      path: "/transactionScores/index",
      component: () => import("@/views/transactionScores/index"),
      name: "transactionScores.index",
      meta: { title: "Счета" },
    },
  ],
};

export default documentType;
