
export const getters = {
    laboratory: state => state.laboratory,
    list: state => state.list,
    inventory: state => state.inventory,
    parent_inventory: state => state.parent_inventory,
    relationInventory: state => state.relationInventory,
    inventory_service_name: state => state.inventory_service_name,
    columns: state => state.columns,
    model: state => state.model,
    filter: state => state.filter,
    pagination: state => state.pagination,
    sort: state => state.sort,
    rules: state => state.rules,
    selected_list: state => state.selected_list,
    relation_list: state => state.relation_list,
    relation_free_list: state => state.relation_free_list,
    relation_list_order: state => state.relation_list_order,
    selected_relation_list: state => state.selected_relation_list,
};