import Layout from '@/layouts'

const documentType = {
  path: '/documentTypes',
  component: Layout,
  name: 'documentTypes',
  redirect: '/documentTypes/index',
  children: [
    {
      path: '/documentTypes/index',
      component: () => import('@/views/documentType/index'),
      name: 'documentTypes.index',
      meta: { title: 'Тип документа'}
    },
  ]
}

export default documentType
