import { i18n } from '@/utils/i18n';

export const columns = {
    id: {
        show: true,
        title: "№",
        sortable: true,
        column: 'id'
    },
    region_id: {
        show: true,
        title:  i18n.t('message.region'),
        sortable: true,
        column: 'region_id'
    },
    city_id: {
        show: true,
        title:  i18n.t('message.city'),
        sortable: true,
        column: 'city_id'
    },

    count: {
        show: true,
        title:  i18n.t('message.quantity'),
        sortable: true,
        column: 'count'
    },
    // settings: {
    //     show: true,
    //     title: i18n.t('message.settings'),
    //     sortable: false,
    //     column: 'settings'
    // }
    // created_at: {
    //     show: true,
    //     title: i18n.t('message.date'),
    //     sortable: true,
    //     column: 'created_at'
    // },
    // updated_at: {
    //     show: false,
    //     title: i18n.t('message.update_date'),
    //     sortable: true,
    //     column: 'updated_at'
    // },
    
};
