<template>
  <div class="input_box new-stylen-te">
    <vue-phone-number-input
      v-model="phone_number"
      :size="size"
      :default-country-code="country_code"
      show-code-on-list
      :translations="{
        countrySelectorLabel: $t('message.country_code'),
        countrySelectorError: 'Choisir un pays',
        phoneNumberLabel: $t('message.tel_number'),
        example: $t('message.exemple'),
      }"
      @update="getNumber"
    ></vue-phone-number-input>
  </div>
</template>
<script>
export default {
  props: {
    old: {
      default: null,
    },
    size: {
      default: "sm",
    },
    country_code: {
      default: "UZ",
    },
  },
  watch: {
    old: {
      handler: function (newVal) {
        this.phone_number = newVal;
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      phone_number: null,
    };
  },
  methods: {
    getNumber(phone) {
      let is_valid = true;
      if (
        phone.formattedNumber &&
        phone.formattedNumber.length != 13 &&
        phone.country_code == "UZ"
      ) {
        is_valid = false;
      }
      this.$emit("input", phone.formattedNumber);
      this.$emit("getPhoneStatus", {
        is_valid: is_valid,
        countryCode: phone.countryCode,
        calling_code: phone.countryCallingCode,
      });
    },
  },
};
</script>
<style>
.vue-tel-input {
  background-color: #fff;
  border: 1px solid #dcdfe6 !important;
}
.new-stylen-te .vti__input {
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  border-left: 1px solid #dcdfe6;
}
.new-stylen-te .vti__input:focus,
.new-stylen-te .vti__input:active {
  box-shadow: initial !important;
}
</style>