import { sort } from "../properties/sort";
import { filter } from "./properties/filter";
import { pagination } from "../properties/pagination";
import { model } from "./properties/model";
import { i18n } from '@/utils/i18n';

export const mutations = {

    SET_PATIENT_LIST: (state, patient_list) => (state.patient_list = patient_list),
    SET_PATIENT_MEDICINE: (state, patient_medicine) => (state.patient_medicine = patient_medicine),
    SET_FILTER: (state, filter) => (state.filter = filter),
    SET_PAGINATION: (state, pagination) => (state.pagination = pagination),
    SET_SORT: (state, sort) => (state.sort = sort),
    SET_MODEL_RELATION: (state, model) => (state.relation_model = model),

    SET_MODEL_ORDER: (state, model_order) => {
        state.model_order = model_order;
    },
    SET_MODEL: (state, model) => {
        state.model = model;
    },
    UPDATE_COLUMN: (state, obj) => {
        state.columns[obj.key].show = obj.value;
    },
    UPDATE_SORT: (state, sort) => {
        state.sort[sort.column] = sort.order;
    },
    UPDATE_PAGINATION: (state, pagination) => {
        state.pagination[pagination.key] = pagination.value
    },
    EMPTY_MODEL: (state) => {
        state.model = JSON.parse(JSON.stringify(model));
    },
    REFRESH: (state) => {
        state.filter = JSON.parse(JSON.stringify(filter));
        state.sort = JSON.parse(JSON.stringify(sort));
        state.pagination = JSON.parse(JSON.stringify(pagination));
    },
    UPDATE_LANG_COLUMN: (state) => {
        state.columns.id.title = "№";
        state.columns.user_id.title = i18n.t('message.doctor_0');
        state.columns.service_id.title = i18n.t('message.service');
        state.columns.condition_id.title = i18n.t('message.condition');
        state.columns.patient_id.title = i18n.t('message.patient');

        state.columns.created_at.title = i18n.t('message.date');
        state.columns.updated_at.title = i18n.t('message.update_date');
        state.columns.settings.title = i18n.t('message.settings');
    }
};