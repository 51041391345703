import Layout from '@/layouts'

const paymentType = {
  path: '/buyReadyProducts',
  component: Layout,
  name: 'buyReadyProducts',
  redirect: '/buyReadyProducts/index',
  children: [{
    path: '/buyReadyProducts/index',
    component: () => import('@/views/buyReadyProducts/index'),
    name: 'buyReadyProducts.index',
    meta: {
      title: 'Закупки продукции'
    }
  }, ]
}

export default paymentType