import { i18n } from '@/utils/i18n';

export const columns = {
    id: {
        show: true,
        title: "№",
        sortable: true,
        column: 'id'
    },
    first_name : {
        show: true,
        title: i18n.t('message.first_name'),
        sortable: true,
        column: 'first_name'
    },
    surname: {
        show: true,
        title: i18n.t('message.surname'),
        sortable: true,
        column: 'surname'
    },
    passport_number: {
        show: false,
        title:  i18n.t('message.passport_number'),
        sortable: false,
        column: "passport_number",
    },
    drive_license: {
        show: false,
        title:  i18n.t('message.drive_license'),
        sortable: false,
        column: "drive_license",
    },
    patronymic: {
        show: true,
        title: i18n.t('message.patronymic'),
        sortable: true,
        column: 'patronymic'
    },
    phone : {
        show: true,
        title: i18n.t('message.phone'),
        sortable: true,
        column: 'phone'
    },
    born_date : {
        show: true,
        title:i18n.t('message.dob'),
        sortable: true,
        column: 'born_date'
    },
    organization_id: {
        show: false,
        title: i18n.t('message.organization'),
        sortable: true,
        column: 'organization_id'
    },
    document_type_id: {
        show: false,
        title: i18n.t('message.type_documents'),
        sortable: true,
        column: 'document_type_id'
    },
    series_and_number: {
        show: false,
        title: i18n.t('message.series_and_number'),
        sortable: false,
        column: 'series_and_number'
    },
    date_of_issue: {
        show: false,
        title: i18n.t('message.date_of_issue'),
        sortable: true,
        column: 'date_of_issue'
    },
    authority: {
        show: false,
        title: i18n.t('message.authority'),
        sortable: true,
        column: 'authority'
    },
    state_id: {
        show: false,
        title: i18n.t('message.country'),
        sortable: true,
        column: 'state_id'
    },
    city_id: {
        show: false,
        title: i18n.t('message.city'),
        sortable: true,
        column: 'city_id'
    },
    region: {
        show: false,
        title: i18n.t('message.region'),
        sortable: true,
        column: 'region'
    },
    street: {
        show: false,
        title: i18n.t('message.street'),
        sortable: true,
        column: 'street'
    },
    area: {
        show: false,
        title: i18n.t('message.district'),
        sortable: true,
        column: 'area'
    },
    home: {
        show: false,
        title: i18n.t('message.house'),
        sortable: true,
        column: 'home'
    },
    flat: {
        show: false,
        title: i18n.t('message.flat'),
        sortable: true,
        column: 'flat'
    },
    social_id: {
        show: false,
        title: i18n.t('message.social'),
        sortable: true,
        column: 'social_id'
    },
    code : {
        show: false,
        title: i18n.t('message.code_id'),
        sortable: true,
        column: 'code'
    },
    inn : {
        show: false,
        title: i18n.t('message.inn'),
        sortable: true,
        column: 'inn'
    },
    onlis : {
        show: false,
        title: i18n.t('message.onlis'),
        sortable: true,
        column: 'onlis'
    },
    gender : {
        show: false,
        title: i18n.t('message.gender'),
        sortable: true,
        column: 'gender'
    },
    created_at : {
        show: false,
        title: i18n.t('message.date'),
        sortable: true,
        column: 'created_at'
    },
    updated_at : {
        show: false,
        title: i18n.t('message.update_date'),
        sortable: false,
        column: 'updated_at'
    },
    settings: {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    }
};