import { actions } from './score/actions'
import { getters } from './score/getters'
import { state } from './score/state'
import { mutations } from './score/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}
