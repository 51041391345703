import Layout from "@/layouts";

const invoice = {
  path: "/invoice",
  component: Layout,
  name: "invoice",
  redirect: "/invoice/index",
  children: [
    {
      path: "/invoice/index",
      component: () => import("@/views/invoice/index"),
      name: "invoice.index",
      meta: { title: "Счет-фактуры" },
    },
  ],
};

export default invoice;
