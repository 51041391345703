import { i18n } from '@/utils/i18n';

export const columns = {
    id: {
        show: true,
        title: "№",
        sortable: true,
        column: 'id'
    },
    name: {
        show: true,
        title: i18n.t('message.name'),
        sortable: true,
        column: 'name'
    },
    total_price: {
        show: true,
        title: i18n.t('message.total_amount'),
        sortable: true,
        column: 'total_price'
    },
    bonus_amount: {
        show: true,
        title: i18n.t('message.bonus_amount'),
        sortable: true,
        column: 'bonus_amount'
    },
    bonus_paid_amount: {
        show: true,
        title: i18n.t('message.bonus_paid_amount'),
        sortable: true,
        column: 'bonus_paid_amount'
    },
    bonus_debt: {
        show: true,
        title: i18n.t('message.bonus_debt'),
        sortable: true,
        column: 'bonus_debt'
    },
    order_count: {
        show: true,
        title: i18n.t('message.order_count'),
        sortable: true,
        column: 'order_count'
    },
    created_at: {
        show: false,
        title: i18n.t('message.date'),
        sortable: true,
        column: 'created_at'
    },
    updated_at: {
        show: false,
        title: i18n.t('message.update_date'),
        sortable: true,
        column: 'updated_at'
    },
    settings: {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    }
};