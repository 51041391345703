import Layout from '@/layouts'

const paymentType = {
  path: '/resources',
  component: Layout,
  name: 'resources',
  redirect: '/resources/index',
  children: [{
    path: '/resources/index',
    component: () => import('@/views/resources/index'),
    name: 'resources.index',
    meta: {
      title: 'Ресурсы'
    }
  }, ]
}

export default paymentType