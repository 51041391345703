import request from '../utils/request'

export function show(id) {
  return request({
    url: `/owner-branch-header/${id}`,
    method: 'get'
  })
}

export function store(data) {
  return request({
    url: '/owner-branch-header',
    method: 'post',
    data
  })
}

export function update(params) {
  return request({
    url: `/owner-branch-header/${params.id}`,
    method: 'post',
    data: params.data
  })
}

export function getOwner(id) {
  return request({
    url: `/owner-branch-header/getOwner/${id}`,
    method: 'get',

  })
}

