import { doctor_list } from "../../../api/users";

export const getters = {
    list: state => state.list,
    model: state => state.model,
    rules: state => state.rules,
    columns: state => state.columns,
    filter: state => state.filter,
    free_list: state => state.free_list,
    pagination: state => state.pagination,
    sort: state => state.sort,
    statues: state => state.statues,
    permissions: state => state.permissions,
    spess: state => state.spess,
    inventory: state => state.inventory,
    inventory_general: state => state.inventory_general,
    doctor_list: state => state.doctor_list,
};