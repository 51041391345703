import { i18n } from '@/utils/i18n';
export const columns = {
  id: {
    show: true,
    title: '№',
    sortable: true,
    column: 'id'
  },
  name: {
    show: true,
    title: i18n.t('message.name'),
    sortable: true,
    column: 'name'
  },
  full_name: {
    show: true,
    title: i18n.t('message.full_name'),
    sortable: true,
    column: 'name'
  },
  code: {
    show: true,
    title: i18n.t('message.code_id'),
    sortable: true,
    column: 'code'
  },
  phone: {
    show: true,
    title: i18n.t('message.phone_number'),
    sortable: true,
    column: 'name'
  },
  fax: {
    show: true,
    title: i18n.t('message.fax'),
    sortable: true,
    column: 'fax'
  },
  email: {
    show: true,
    title: i18n.t('message.email'),
    sortable: true,
    column: 'email'
  },
  description: {
    show: true,
    title: i18n.t('message.description'),
    sortable: true,
    column: 'description'
  },
  actual_address: {
    show: true,
    title: i18n.t('message.address'),
    sortable: true,
    column: 'actual_address'
  },
  type: {
    show: true,
    title: i18n.t('message.type'),
    sortable: true,
    column: 'type'
  },
  legal_address: {
    show: true,
    title: i18n.t('message.legal_address'),
    sortable: true,
    column: 'legal_address'
  },
  inn: {
    show: true,
    title: i18n.t('message.inn'),
    sortable: true,
    column: 'inn'
  },
  mfo: {
    show: true,
    title: i18n.t('message.mfo'),
    sortable: true,
    column: 'mfo'
  },
  okonx: {
    show: true,
    title: i18n.t('message.okonx'),
    sortable: true,
    column: 'okonx'
  },
  oked: {
    show: true,
    title: i18n.t('message.oked'),
    sortable: true,
    column: 'oked'
  },
  rkp_nds: {
    show: true,
    title: i18n.t('message.rkp_nds'),
    sortable: true,
    column: 'rkp_nds'
  },
  created_at: {
    show: true,
    title: i18n.t('message.date'),
    sortable: true,
    column: 'created_at'
  },
  updated_at: {
    show: true,
    title: i18n.t('message.update_date'),
    sortable: true,
    column: 'updated_at'
  },
  settings: {
    show: true,
    title: i18n.t('message.settings'),
    sortable: false,
    column: 'settings'
  },
  bank: {
    bank: {
      title: i18n.t('message.bank')
    },
    address: {
      title: i18n.t('message.address')
    },
    correspondent_account: {
      title: i18n.t('message.correspondent_account')
    },
    checking_account: {
      title: i18n.t('message.accaunt')
    }
  },
  contact: {
    full_name: {
      title: i18n.t('message.full_name'),
    },
    position: {
      title: i18n.t('message.position'),
    },
    phone: {
      title: i18n.t('message.phone_number'),
    },
    email: {
      title: i18n.t('message.email'),
    },
    description: {
      title: i18n.t('message.description'),
    }
  }
};