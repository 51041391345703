import Layout from '@/layouts'

const organization = {
    path: '/organizations',
    component: Layout,
    name: 'organizations',
    redirect: '/organizations/index',
    children: [
      {
        path: '/organizations/index',
        component: () => import('@/views/organization/index'),
        name: 'organizations.index',
        meta: { title: 'Организации'}
      },
    ]
  }

export default organization
