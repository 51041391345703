import request from "../utils/request"

export function index(params) {
  return request({
    url: "/pockets",
    method: "get",
    params,
  })
}
export function getPhysio(params) {
  return request({
    url: "/pockets/getPhysio",
    method: "get",
    params,
  })
}
export function show(id) {
  return request({
    url: `/pockets/${id}`,
    method: "get",
  })
}
export function store(data) {
  return request({
    url: "/pockets",
    method: "post",
    data,
  })
}

export function update(data) {
  return request({
    url: `/pockets/${data.id}`,
    method: "put",
    data,
  })
}

export function destroy(id) {
  return request({
    url: `/pockets/${id}`,
    method: "delete",
  })
}
