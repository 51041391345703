export const model = {
    id: null,
    service_id: "",
    doctor_id: "",
    brief_conclusion: "",
    sick_number_id: "",
    patient_id: "",
    status: "",
    form_template_id: "",
    created_at: '',
    updated_at: ''
};
