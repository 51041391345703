import { actions } from './paymentBonus/actions'
import { getters } from './paymentBonus/getters'
import { state } from './paymentBonus/state'
import { mutations } from './paymentBonus/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}