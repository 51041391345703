export const rules = {
    name: [
        { required: true, message: 'Пожалуйста, введите комната ', trigger: 'blur' },
    ],
    corps_id: [
        { required: true, message: 'Пожалуйста, введите корпус ', trigger: 'blur' },
    ],
    floor_id: [
        { required: true, message: 'Пожалуйста, введите этаж ', trigger: 'blur' },
    ],
    classs_id: [
        { required: true, message: 'Пожалуйста, введите класс ', trigger: 'blur' },
    ],
};
