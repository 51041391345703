import request from './../utils/request';

export function index(params) {
    return request({
      url: '/score',
      method: 'get',
      params
    })
}

export function inventory(params) {
  return request({
    url: '/score/inventory',
    method: 'get',
    params
  })
}

export function show(id) {
  return request({
    url: `/score/${id}`,
    method: 'get'
  })
}

export function store(data) {
  return request({
    url: '/score',
    method: 'post',
    data
  })
}

export function update(data) {
  return request({
    url: `/score/${data.id}`,
    method: 'put',
    data
  })
}

export function destroy(id) {
  return request({
    url: `/score/${id}`,
    method: 'delete',
  })
}

