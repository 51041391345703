import { actions } from './organization/actions'
import { getters } from './organization/getters'
import { state } from './organization/state'
import { mutations } from './organization/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}
