import Layout from '@/layouts'

const city = {
  path: '/moving',
  component: Layout,
  name: 'moving',
  redirect: '/moving/index',
  children: [{
    path: '/moving/index',
    component: () => import('@/views/moving/index'),
    name: 'moving.index',
    meta: {
      title: 'Перемещение'
    }
  }, ]
}

export default city