import { i18n } from '@/utils/i18n';

export const columns = {
    id: {
        show: true,
        title: "№",
        sortable: true,
        column: 'id'
    },
    first_name: {
        show: true,
        title: i18n.t('message.first_name'),
        sortable: true,
        column: 'first_name'
    },

    surname: {
        show: true,
        title: i18n.t('message.surname'),
        sortable: true,
        column: 'surname'
    },
    patronymic: {
        show: true,
        title: i18n.t('message.patronymic'),
        sortable: true,
        column: 'patronymic'
    },
    state: {
        show: false,
        title: i18n.t('message.country'),
        sortable: true,
        column: 'state'
    },
    city: {
        show: false,
        title: i18n.t('message.city'),
        sortable: true,
        column: 'city'
    },
    region: {
        show: false,
        title: i18n.t('message.region'),
        sortable: true,
        column: 'region'
    },
    passport_number: {
        show: false,
        title: i18n.t('message.passport_number'),
        sortable: true,
        column: 'passport_number'
    },

    dob: {
        show: true,
        title: i18n.t('message.dob'),
        sortable: true,
        column: 'born_date'
    },

    code: {
        show: false,
        title: i18n.t('message.code_id'),
        sortable: true,
        column: 'code'
    },
    // room: {
    //     show: true,
    //     title: i18n.t('message.room'),
    //     sortable: true,
    //     column: 'room'
    // },
    gender: {
        show: false,
        title: i18n.t('message.gender'),
        sortable: true,
        column: 'gender'
    },
    hospitalization_type: {
        show: true,
        title: i18n.t('message.hospitalization_type'),
        sortable: true,
        column: 'branch_id'
    },
    doctor: {
        show: true,
        title: i18n.t('message.doctor'),
        sortable: true,
        column: 'name'
    },
    button: {
        show: true,
        title:  i18n.t('message.view_0'),
        sortable: true,
        column: 'button'
    },
    // data_arrive: {
    //     show: true,
    //     title: i18n.t('message.arrival_date'),
    //     sortable: true,
    //     column: 'data_arrive'
    // },
    // data_departure: {
    //     show: true,
    //     title: i18n.t('message.departure_date'),
    //     sortable: true,
    //     column: 'data_departure'
    // },
    // patient_schedule_id: {
    //     show: true,
    //     arrive: "Дата прибытия",
    //     departure: "Дата отъезда",
    //     sortable: true,
    //     column: 'patient_schedule_id'
    // },
    hospitalization_created_at: {
        show: true,
        title: i18n.t('message.date_hospitalization'),
        sortable: true,
        column: 'created_at'
    },
    created_at: {
        show: true,
        title: i18n.t('message.date'),
        sortable: true,
        column: 'created_at'
    },
    updated_at: {
        show: false,
        title: i18n.t('message.update_date'),
        sortable: true,
        column: 'updated_at'
    },
    settings: {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    }
};
