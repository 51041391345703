export const filter = {
    search: "",
    id: "",
    branch: "",
    val: "",
    start_date: "",
    end_date: "",
    tenant: "",
    dogNamber: ""
};
