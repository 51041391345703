export const rules = {
    name: [
        { required: true, message: 'Пожалуйста укажите наименование', trigger: 'change' },
    ],
    rate: [
        { required: true, message: 'Пожалуйста укажите курс', trigger: 'change' },
    ],
    symbol: [
        { required: true, message: 'Пожалуйста укажите символ', trigger: 'change' },
    ],
    code: [
        { required: true, message: 'Пожалуйста укажите код', trigger: 'change' },
    ],
};
