<template>
  <el-select
    :value="selected"
    @input="dispatch"
    :placeholder="plc || 'Пациент'"
    filterable
    clearable
    :size="size"
    class="d-block"
  >
    <el-option
      v-for="(patient,index) in patients"
      :key="'patients-' + index"
      :label="patient.surname + ' ' + patient.first_name" 
      :value="patient.id"
    ></el-option>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import mix from "@/utils/mixins/components/inventory";

export default {
  name: "patients",
  mixins: [mix],
  mounted() {
    if (this.patients && this.patients.length === 0) this.updateInventory();
  },
  computed: {
    ...mapGetters({
      patients: "patients/inventory"
    })
  },
  methods: {
    ...mapActions({
      updateInventory: "patients/inventory"
    })
  }
};
</script>