<template>
  <el-select
    :value="selected"
    @change="dispatch"
    :placeholder="plc || $t('message.city')"
    filterable
    clearable
    remote
    :remote-method="searchCitiesWithLoad"
  >
    <el-option
      v-for="item in filtered"
      :key="item.id"
      :label="item.name"
      :value="item.id"
    ></el-option>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import inventory from "@/utils/mixins/components/inventory";

export default {
  mixins: [inventory],
  props: ["city_id", "region_id", "state_id", "id"],
  mounted() {
    if (this.cities && this.cities.length === 0) {
      this.getInventory();
    }
  },
  watch: {
    id: {
      handler: function () {
        setTimeout(() => {
          this.selected = this.id;
        });
        this.getInventory({
          selected_id: this.id,
        });
      },
      deep: true,
      immediate: true,
    },
    state_id: function (newVal, oldVal) {
      if (
        newVal != oldVal &&
        !(
          typeof this.filtered.find((o) => o.region_id == this.region_id) ==
          "object"
        )
      ) {
        this.selected = null;
        this.$emit("input", this.selected);
      }
    },
    region_id: function (newVal, oldVal) {
      if (newVal != oldVal && parseInt(newVal, 10) == newVal) {
        this.getInventory({
          selected_id: this.id,
          state_id: this.state_id,
          region_id: newVal,
        }).then((res) => {
          if (
            !(
              typeof res.data.cities.find((o) => o.region_id == newVal) ==
              "object"
            )
          ) {
            this.selected = null;
            this.$emit("input", this.selected);
          }
        });
      }
    },
    city_id: function (val, oldVal) {
      this.getInventory({
        selected_id: val,
        state_id: this.state_id,
        region_id: this.region_id,
      }).then((res) => {
        if (val != null) this.emitParent(val);
      });
      this.selected = val;
      this.$emit("input", this.selected);
    },
  },
  computed: {
    ...mapGetters({
      cities: "cities/inventory",
    }),
    filtered: function () {
      if (this.region_id) {
        return this.cities.filter((o) => {
          return this.region_id == o.region_id;
        });
      } else {
        return this.cities;
      }
    },
  },
  methods: {
    ...mapActions({
      getInventory: "cities/inventory",
    }),
    emitParent(val) {
      let region = this.cities.find((o) => {
        return val == o.id;
      });
      if (region) this.$emit("region_id", region.region_id);
    },
    searchCities: _.debounce(function (val) {
      this.getInventory({ search: val, region_id: this.region_id });
    }, 500),
    searchCitiesWithLoad(val) {
      this.searchCities(val);
    },
  },
};
</script>
