<template>
  <el-select
    :value="selected"
    @input="dispatch"
    :placeholder="plc || 'Поставщик'"
    filterable
    clearable
    :size="size"
    class="d-block"
  >
    <el-option 
      v-for="supplier in suppliers"
      :key="supplier.id"
      :label="supplier.name"
      :value="supplier.id"
    ></el-option>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import inventory from '@/utils/mixins/components/inventory';

export default {
  mixins: [
    inventory
  ],
  props: [
    'supplier_id'
  ],
  watch: {
    supplier_id: {
      handler: function () {
        this.dispatch(this.supplier_id);
      },
      deep: true,
      immediate: true,
    }
  },
  mounted() {
    if (this.suppliers && this.suppliers.length === 0) {
      this.getInventory();
    }
  },
  computed: {
    ...mapGetters({
      suppliers: 'suppliers/inventory'
    })
  },
  methods: {
    ...mapActions({
      getInventory: 'suppliers/inventory'
    })
  }
}
</script>