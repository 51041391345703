export const model = {
    id: null,
    index: '', 
    day: '', 
    comment: '', 
    time: '', 
    daily_treatment_id: '', 
    created_at: '',
    updated_at: ''
};
