<template>
  <el-select
    :value="selected"
    @input="dispatch"
    :placeholder="plc || 'Договор'"
    filterable
    clearable
    :size="size"
    class="d-block"
  >
    <el-option 
      v-for="supplierContract in supplierContracts"
      :key="supplierContract.id"
      :label="supplierContract.number"
      :value="supplierContract.id"
    ></el-option>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import inventory from '@/utils/mixins/components/inventory';

export default {
  mixins: [
    inventory
  ],
  props: [
    'supplier_contract_id'
  ],
  watch: {
    supplier_contract_id: {
      handler: function () {
        this.dispatch(this.supplier_contract_id);
      },
      deep: true,
      immediate: true,
    }
  },
  mounted() {
    if (this.supplierContracts && this.supplierContracts.length === 0) {
      this.getInventory();
    }
  },
  computed: {
    ...mapGetters({
      supplierContracts: 'supplierContracts/inventory'
    })
  },
  methods: {
    ...mapActions({
      getInventory: 'supplierContracts/inventory'
    })
  }
}
</script>