<template>
  <el-select
    :value="selected"
    @input="dispatch"
    :placeholder="plc || $t('message.type_of_injury')"
    filterable
    clearable
  >
    <el-option
      v-for="item in injuryTypes"
      :key="item.id"
      :label="item.name"
      :value="item.id"
    ></el-option>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import inventory from "@/utils/mixins/components/inventory";

export default {
  mixins: [inventory],
  props: ["injury_type_id"],
  mounted() {
    if (this.injuryTypes && this.injuryTypes.length === 0) {
      this.getInventory();
    }
  },
  watch: {
    injury_type_id: {
      handler: function (val, oldVal) {
        this.selected = val;
        this.$emit("input", this.selected);
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      injuryTypes: "injuryTypes/inventory",
    }),
  },
  methods: {
    ...mapActions({
      getInventory: "injuryTypes/inventory",
    }),
  },
};
</script>
