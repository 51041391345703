import request from './../utils/request';

export function index(params) {
  return request({
    url: '/hospitalizationServices',
    method: 'GET',
    params
  });
}

export function indexitems(params) {
  return request({
    url: `/hospitalizationServices/indexitems/${params.hospital_patient_id}`,
    method: 'GET',
    params
  });
}
export function inventory(params) {
  return request({
    url: '/hospitalizationServices/inventory',
    method: 'get',
    params
  })
}

export function show(id) {
  return request({
    url: `/hospitalizationServices/${id}`,
    method: 'get'
  })
}

export function getservices(id) {
  return request({
    url: `/hospitalizationServices/getservices/${id}`,
    method: 'get'
  })
}

export function store(data) {
  return request({
    url: '/hospitalizationServices',
    method: 'POST',
    data
  });
}

export function update(data) {
  return request({
    url: '/hospitalizationServices/' + data.id,
    method: 'PUT',
    data
  });
}

export function destroy(id) {
  return request({
    url: '/hospitalizationServices/' + id,
    method: 'DELETE'
  });
}

export function destroyItem(id) {
  return request({
    url: `/hospitalizationServices/item/${id}`,
    method: 'DELETE'
  });
}

