import Layout from '@/layouts'

const schedule = {
  path: '/letterhead',
  component: Layout,
  name: 'letterhead',
  redirect: '/letterhead/index',
  children: [{
    path: '/letterhead/index',
    component: () => import('@/views/letterhead/index'),
    name: 'letterhead.index',
    meta: {
      title: 'График'
    }
  }, ]
}

export default schedule