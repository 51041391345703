export const model = {
    address: '',
    created_at: '',
    deleted_at: '',
    email: '',
    id: '',
    image: '',
    phone_number: '',
    updated_at: '',
    website: '',

};