import request from './../utils/request'


export function index(params) {
  	return request({
  	  url: '/print/auditable',
  	  method: 'get',
        params
  	})
  }

  export function show(params) {
    return request({
      url: `/print/auditable/${params.id}`,
	  method: 'get',
	  params
    })
  }
