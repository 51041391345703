import { index, show, store, update, destroy } from '../../../api/remainders';

export const actions = {
  index({ commit }, params = {}) {
    return new Promise((resolve, reject) => {
      index(params)
        .then(response => {
          commit('SET_LIST', response.data.data.products.data);
          commit('UPDATE_PAGINATION', {
            key: 'total',
            value: response.data.data.products.total
          });
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  show({ commit }, params = {}) {
    return new Promise((resolve, reject) => {
      show(params)
        .then(response => {
          commit('SET_MODEL', response.data.data.product);
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  updateSort({ commit }, sort) {
    commit('SET_SORT', sort);
  },
  updateFilter({ commit }, filter) {
    commit('SET_FILTER', JSON.parse(JSON.stringify(filter)));
  },
  updateColumn({ commit }, column) {
    commit('UPDATE_COLUMN', column);
  },
  updateColumnForStock({ commit }, column) {
    commit('SET_COLUMN_STOCK', column);
  },
  updatePagination({ commit }, pagination) {
    commit('UPDATE_PAGINATION', pagination);
  },
  refreshData({ commit }) {
    return new Promise((resolve, reject) => {
      commit('REFRESH');
      resolve();
    });
  },
  empty({ commit }) {
    commit('EMPTY_MODEL');
  }
};