import { actions } from './dailyProcessReport/actions'
import { getters } from './dailyProcessReport/getters'
import { state } from './dailyProcessReport/state'
import { mutations } from './dailyProcessReport/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}
