import {sort} from "../properties/sort";
import {filter} from "./properties/filter";
import {pagination} from "../properties/pagination";
import {model} from "./properties/model";
import { i18n } from '@/utils/i18n';

export const mutations = {
    SET_LIST: (state, workTimes) => (state.list = workTimes),
    SET_FILTER: (state,filter) => (state.filter = filter),
    SET_INVENTORY: (state, inventory) => (state.inventory = inventory),
    SET_MODEL: (state,model) => {
        state.model.id = model.id;
        state.model.name = model.name;
        state.model.doctors = model.doctors;
    },
    EMPTY_MODEL: (state) => {
        state.model = JSON.parse( JSON.stringify( model ));
    },
    REFRESH: (state) => {
        state.filter = JSON.parse( JSON.stringify( filter ));
    },
    UPDATE_LANG_COLUMN: (state) => {
        state.columns.from.title = i18n.t('message.from_time');
        state.columns.to.title = i18n.t('message.to_time');
        state.columns.room_id.title = i18n.t('message.room');
    }
};
