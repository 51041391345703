import {sort} from "../properties/sort";
import {filter} from "./properties/filter";
import {pagination} from "../properties/pagination";
import {model} from "./properties/model";
import { i18n } from '@/utils/i18n';

export const mutations = {
    SET_LIST: (state, patientHistories) => {
        state.list = patientHistories
    },
    SET_FILTER: (state,filter) => (state.filter = filter),
    SET_INVENTORY: (state,list) => state.inventory = list,
    SET_PAGINATION: (state,pagination) => (state.pagination = pagination),
    SET_LAST_ID: (state, lastId) => (state.lastId = lastId),
    INCREMENT_LAST_ID: (state) => (++state.lastId),
    SET_SORT: (state,sort) => (state.sort = sort),
    SET_MODEL: (state,model) => {
        state.model = model;
    },
    UPDATE_COLUMN: (state, obj) => {
        state.columns[obj.key].show = obj.value
    },
    UPDATE_SORT: (state, sort) => {
        state.sort[sort.column] = sort.order
    },
    UPDATE_PAGINATION: (state, pagination) => {
        state.pagination[pagination.key] = pagination.value
    },
    EMPTY_MODEL: (state) => {
        state.model = JSON.parse( JSON.stringify( model ));
    },
    REFRESH: (state) => {
        state.filter = JSON.parse( JSON.stringify( filter ));
        state.sort = JSON.parse( JSON.stringify( sort ));
        state.pagination = JSON.parse( JSON.stringify( pagination ));
    },
    UPDATE_LANG_COLUMN: (state) => {
            state.columns.id.title = "№";
            state.columns.hospitalPatient.title = i18n.t('message.first_name');
            state.columns.surname.title = i18n.t('message.surname');
            state.columns.dob.title = i18n.t('message.dob');
            state.columns.code.title = i18n.t('message.code_id');
            state.columns.room.title = i18n.t('message.room');
            state.columns.gender.title = i18n.t('message.gender');
            state.columns.button.title =  i18n.t('message.view_0');
            state.columns.data_arrive.title = i18n.t('message.arrival_date');
            state.columns.data_departure.title = i18n.t('message.departure_date');
            state.columns.created_at.title = i18n.t('message.date');
            state.columns.updated_at.title = i18n.t('message.update_date');
            state.columns.settings.title = i18n.t('message.settings');
        
    } 
};
