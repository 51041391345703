import request from '../utils/request'


export function index(params) {
  	return request({
  	  url: '/patientIndicators',
  	  method: 'get',
      params
  	})
  }
  export function inventory(params) {
    return request({
      url: '/patientIndicators/inventory',
      method: 'get',
      params
    })
  }
  export function show(id) {
    return request({
      url: `/patientIndicators/${id}`,
      method: 'get'
    })
  }

  // export function showNumber(params) {
  //   return request({
  //     url: `/dailyTreatments/showNumber`,
  //     method: 'get',
  //     params
  //   })
  // }

  export function store(data) {
    return request({
      url: '/patientIndicators',
      method: 'post',
      data
    })
  }

  export function update(data) {
    return request({
      url: `/patientIndicators/${data.id}`,
      method: 'put',
      data
    })
  }

  export function destroy(id) {
    return request({
      url: `/patientIndicators/${id}`,
      method: 'delete',
    })
  }
