export const getters = {
    money: state => state.money,
    noSuffixMoney: state => state.noSuffixMoney,
    token: state => state.auth.token,
    name: state => state.auth.name,
    phone: state => state.auth.phone,
    role: state => state.auth.role,
    current_tab: state => state.current_tab,
    newMoney: state => state.newMoney,
    mobileMenu: state => state.mobileMenu
}