export const rules = {
    transactionable_id: [
        { required: true, message: 'Пожалуйста, выберите тип расхода', trigger: 'change' },
    ],
    payment_type_id: [
        { required: true, message: 'Пожалуйста, выберите тип оплаты', trigger: 'change' },
    ],
    price: [
        { required: true, message: 'Пожалуйста, введите цена', trigger: 'blur' },
    ],
    currency_id: [
        { required: true, message: 'Пожалуйста, выберите валюту', trigger: 'change' },
    ],
    rate: [
        { required: true, message: 'Пожалуйста, введите курс валюты', trigger: 'blur' },
    ],
};
