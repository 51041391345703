import {
    sort
} from "../properties/sort";
import {
    filter
} from "./properties/filter";
import {
    pagination
} from "../properties/pagination";
import {
    model
} from "./properties/model";
import {
    i18n
} from '@/utils/i18n';

export const mutations = {
    SET_LIST: (state, orders) => {
        for (const key in orders) {
            if (orders.hasOwnProperty(key)) {
                const elemen = orders[key];
                elemen.workTime.forEach(val => {
                    val.days = elemen.day
                });
            }
        }
        state.list = orders
        let total = 0;
        let paid = 0;
        for (const key in orders) {
            if (orders.hasOwnProperty(key)) {
                const element = orders[key];
                total += parseFloat(element.total_price)
                for (const i in element.payment) {
                    if (element.payment.hasOwnProperty(i)) {
                        const payment = element.payment[i];
                        paid += parseFloat(payment.paid_price)
                    }
                }

            }
        }
        let not_paid = total - paid;
        state.prices = {
            total_price: total,
            paid_price: paid,
            no_paid: not_paid
        };
    },
    SET_TERM_LIST: (state, patient_list) => {
        state.term_list = patient_list
    },
    SET_PATIENT_LIST: (state, patient_list) => (state.patient_list = patient_list),
    SET_EXPIRED_LIST: (state, patient_list) => (state.expired_list = patient_list),
    SET_PATIENT_MEDICINE: (state, patient_medicine) => (state.patient_medicine = patient_medicine),
    SET_INVENTORY: (state, patients) => (state.inventory = patients),
    SET_FILTER: (state, filter) => (state.filter = filter),
    SET_PAGINATION: (state, pagination) => (state.pagination = pagination),
    SET_EXPIRED_PAGINATION: (state, pagination) => (state.expired_pagination = pagination),
    SET_SORT: (state, sort) => (state.sort = sort),
    SET_EXPIRED_SORT: (state, sort) => (state.expired_sort = sort),
    SET_MODEL_RELATION: (state, model) => { state.relation_model = model },
    SET_MODEL: (state, model) => {
        state.model = model;
    },
    UPDATE_COLUMN: (state, obj) => {
        if (state.columns[obj.key]) {
            state.columns[obj.key].show = obj.value
        }
    },
    UPDATE_COLUMN_NEW: (state, obj) => {
        if (state.columns2[obj.key]) {
            state.columns2[obj.key].show = obj.value
        }
    },
    UPDATE_SORT: (state, sort) => {
        state.sort[sort.column] = sort.order
    },
    UPDATE_EXPIRED_SORT: (state, sort) => {
        state.expired_sort[sort.column] = sort.order
    },
    UPDATE_PAGINATION: (state, pagination) => {
        state.pagination[pagination.key] = pagination.value
    },
    UPDATE_EXPIRED_PAGINATION: (state, pagination) => {
        state.expired_pagination[pagination.key] = pagination.value
    },
    EMPTY_MODEL: (state) => {
        state.model = JSON.parse(JSON.stringify(model));
    },
    REFRESH: (state) => {
        state.filter = JSON.parse(JSON.stringify(filter));
        state.sort = JSON.parse(JSON.stringify(sort));
        state.pagination = JSON.parse(JSON.stringify(pagination));
    },
    UPDATE_LANG_COLUMN: (state) => {
        state.columns.id.title = "№";
        state.columns.patient_id.title = i18n.t('message.patient');
        state.columns.total_price.title = i18n.t('message.total_amount');
        state.columns.paid_price.title = i18n.t('message.paid');
        // state.columns.room_id.title = i18n.t('message.room');
        // state.columns.day.title = i18n.t('message.date_0');
        // state.columns.notes.title = i18n.t('message.notes');
        // state.columns.condition_id.title = i18n.t('message.condition');
        state.columns.color.title = i18n.t('message.color');
        state.columns.status.title = i18n.t('message.status');
        state.columns.partner_doctor_id.title = i18n.t('message.partner_doctor');
        state.columns.partner_clinic_id.title = i18n.t('message.partner_clinic');
        state.columns.laborant.title = i18n.t('message.laborant');
        state.columns.doctor.title = i18n.t('message.doctor_0');
        // state.columns.from_hour.title = i18n.t('message.from_hours');
        // state.columns.duration.title = i18n.t('message.duration');
        state.columns.created_at.title = i18n.t('message.date');
        state.columns.user.title = i18n.t('message.user');
        state.columns.settings.title = i18n.t('message.settings');
    }
};