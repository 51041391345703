import request from './../utils/request'


export function index(params) {
  return request({
    url: '/transactions',
    method: 'get',
    params
  })
}

export function chart(params) {
  return request({
    url: '/transactions/transactions/chart',
    method: 'get',
    params
  })
}

export function refund(params) {
  return request({
    url: '/transactions/refund/update',
    method: 'post',
    params
  })
}

export function reports(params) {
  return request({
    url: '/transaction/reports',
    method: 'get',
    params
  })
}

export function scoreReport(params) {
  return request({
    url: '/transaction/scoreReport',
    method: 'get',
    params
  })
}

export function show(id) {
  return request({
    url: `/transactions/${id}`,
    method: 'get'
  })
}

export function store(data) {
  return request({
    url: '/transactions',
    method: 'post',
    data
  })
}

export function update(data) {
  return request({
    url: `/transactions/${data.id}`,
    method: 'put',
    data
  })
}

export function destroy(id) {
  return request({
    url: `/transactions/${id}`,
    method: 'delete',
  })
}

export function remove(data) {
  return request({
    url: `/payments/delete/transaction`,
    method: 'post',
    data
  })
}

export function excelReport(params) {
  return request({
    url: "/excel-report/transactionScoreExcel",
    method: "get",
    responseType: 'blob',
    params
  });
}
