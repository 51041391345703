<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h" style="display: inline-block">
              <span> {{ $t("message.new_order") }}</span>
            </div>
            <div style="display: inline-block">
              <span class="ml-4">
                <el-tag class="mr-2"
                  >{{ $t("message.quantity") }}: {{ items.length }}</el-tag
                >
                <el-tag class="mr-2" type="success"
                  >{{ $t("message.total_amount") }}:
                  {{ Math.round(totalAmount) | formatMoney }}</el-tag
                >
                <el-tag
                  >{{ $t("message.id_number") }}:
                  {{ this.old_order ? this.old_order.id : "" }}</el-tag
                >
              </span>
            </div>
          </div>
        </el-col>

        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                :loading="waitingPrint"
                :disabled="saveBtn"
                @click="printinsurance(printModel.id)"
                type="success"
                >{{ $t("message.print_insurance") }}</el-button
              >
              <el-button
                :loading="waitingPrint"
                :disabled="saveBtn"
                type="success"
                @click="printMini(printModel.id)"
                >{{ $t("message.print") }}</el-button
              >
              <el-button
                :loading="waitingPrint"
                :disabled="saveBtn"
                @click="printDouble(printModel.id)"
                >{{ $t("message.print_double") }}</el-button
              >
              <el-button
                :loading="waitingPrint"
                :disabled="saveBtn"
                @click="print(printModel.id)"
                >{{ $t("message.print") }}</el-button
              >
              <el-button
                v-if="!old_save"
                :loading="waiting"
                :disabled="!saveBtn || !yes_user"
                @click="submit(false)"
                type="primary"
                >{{ $t("message.save") }}
              </el-button>
              <el-button
                v-if="old_save"
                :loading="waiting"
                @click="updatingOrder(false)"
                type="primary"
                >{{ $t("message.update") }}
              </el-button>
              <el-button
                :loading="waiting"
                :disabled="saveBtn"
                @click="resetForm()"
                type="success"
              >
                {{ $t("message.create_new_patient") }}
              </el-button>
              <el-button :loading="waiting" @click="closeForm()">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <el-tabs
      class="theePopup"
      v-model="activeTab"
      v-loading="loadingData"
      @tab-click="tabEvent"
    >
      <el-tab-pane :label="$t('message.basic_information')" name="first">
        <el-row>
          <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
            <div class="grid-content">
              <div class="add_patient">
                <el-form ref="form" class="from_x">
                  <el-form-item>
                    <el-input
                      v-model="search"
                      @input="searchPatients"
                      clearable
                    ></el-input>
                  </el-form-item>
                </el-form>

                <button class="add_pats my_add_pats" @click="addPatient()">
                  {{ $t("message.add_new_patient") }}
                </button>

                <div class="ow_hiddin">
                  <div class="old_patient" v-if="yes_user">
                    <div
                      class="info_old_patient action"
                      :class="{ active: infoOldSelected }"
                    >
                      <a class="cursor-pointer">
                        <span
                          >{{ user.first_name }} {{ user.surname }}
                          {{ user.patronymic }}</span
                        >
                        <span v-if="user.organization" class="tel_infos">
                          <i class="flaticon-call mr-1"></i> {{ user.phone }}
                          <i class="flaticon-home mr-1 ml-2"></i>
                          {{ user.organization.company_name }}</span
                        >
                        <span v-else class="tel_infos">
                          <i class="flaticon-call mr-1"></i>
                          {{ user.phone }}</span
                        >
                      </a>
                      <a class="icon_ad" @click="openUpdatePatient(user)">
                        <i class="flaticon-report-1"></i>
                      </a>
                    </div>
                  </div>
                  <template v-else>
                    <div
                      class="old_patient"
                      v-for="patient in patients"
                      :key="patient.id"
                    >
                      <div
                        class="info_old_patient"
                        :class="{ active: infoOldSelected }"
                      >
                        <a
                          @click="
                            edit(patient);
                            addClass();
                          "
                        >
                          <span>
                            {{ patient.surname }} {{ patient.first_name }}
                            {{ patient.patronymic }}
                            <small> {{ patient.id }}</small></span
                          >
                          <span v-if="patient.organization" class="tel_infos">
                            <i class="flaticon-call mr-1"></i>
                            {{ patient.phone }}
                            <i class="flaticon-home mr-1 ml-2"></i>
                            {{ patient.organization.company_name }}</span
                          >
                          <span v-else class="tel_infos">
                            <i class="flaticon-call mr-1"></i>
                            {{ patient.phone }}</span
                          >
                        </a>
                        <a class="icon_ad" @click="openUpdatePatient(patient)">
                          <i class="flaticon-report-1"></i>
                        </a>
                      </div>
                    </div>
                  </template>
                </div>
                <ul class="bemor_uslugalar">
                  <li
                    v-for="(item, index) in latestServices.order_services"
                    :key="'index-' + index"
                  >
                    <div>{{ item.service ? item.service.name : "" }}</div>

                    <span>
                      <i
                        v-show="latestServices.conculations.length"
                        round
                        @click="show(latestServices.conculations[0])"
                        class="el-icon-view"
                        style="font-size: 19px; color: #409eff; cursor: pointer"
                      >
                      </i>
                      {{ item.created_at }}</span
                    >
                  </li>
                </ul>
              </div>
            </div>
          </el-col>
          <!-- end el-col -->

          <template v-if="user && user.id">
            <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
              <div class="grid-content">
                <div class="add_patient">
                  <div class="title_cet">
                    {{ $t("message.speciality") }}
                  </div>
                  <div class="radioMarka">
                    <el-col :span="24">
                      <div
                        :label="$t('message.speciality')"
                        class="mb-2"
                        prop="doctor_id"
                      >
                        <el-select
                          v-model="specialty_id"
                          clearable
                          :placeholder="$t('message.specialities')"
                        >
                          <el-option
                            v-for="item in specialties"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                          >
                          </el-option>
                        </el-select>
                      </div>
                    </el-col>
                  </div>

                  <!-- room  -->

                  <!-- room  -->
                </div>
              </div>
              <div class="grid-content">
                
                <div class="add_patient">
                  <div class="title_cet">
                    {{ $t("message.doctor_0") }}
                  </div>
                  <div class="radioMarka">
                    <el-col :span="24">
                      <div
                        :label="$t('message.doctor_0')"
                        class="mb-2"
                        prop="doctor_id"
                      >
                        <users
                          @parent-event="parentEvent"
                          :specialty_id="specialty_id"
                          v-model="form.doctor_id"
                          :doctor_id="form.doctor_id"
                        ></users>
                      </div>
                    </el-col>
                  </div>

                  <!-- room  -->

                  <!-- room  -->
                </div>
              </div>
              <!-- end grid-content -->

              <div class="grid-content">
                <div class="add_patient pt-0">
                  <div class="title_cet">
                    {{ $t("message.condition") }}
                  </div>
                  <div class="radioMarka">
                    <el-radio-group v-model="form.condition_id">
                      <el-radio
                        v-for="condition in conditions"
                        :key="condition.id"
                        :disabled="condition.status === 'cancel'"
                        :label="condition.id"
                        >{{ condition.name }}</el-radio
                      >
                    </el-radio-group>
                  </div>
                </div>
              </div>
              <!-- end grid-content -->

              <div class="grid-content">
                <div class="add_patient">
                  <div class="textarea mt-3">
                    <div class="sub-title mb-3">{{ $t("message.notes") }}</div>
                    <el-input
                      v-model="form.notes"
                      type="textarea"
                      maxlength="30"
                      show-word-limit
                    >
                    </el-input>
                  </div>
                </div>
              </div>
              <!-- end grid-content -->
            </el-col>
            <!-- end el-col -->
            <!-- <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
              <div class="ml-5 mr-5 mt-3">
                <ul class="bemor_uslugalar">
                  <li
                    v-for="(item, index) in latestServices.order_services"
                    :key="'index-' + index"
                  >
                    <div>{{ item.service ? item.service.name : "" }}</div>

                    <span>
                      <i
                        v-show="latestServices.conculations.length"
                        round
                        @click="show(latestServices.conculations[0])"
                        class="el-icon-view"
                        style="font-size: 19px; color: #409eff; cursor: pointer"
                      >
                      </i>
                      {{ item.created_at }}</span
                    >
                  </li>
                </ul>
              </div>
            </el-col> -->
            <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
              <div class="ml-5 mr-5 mt-3" v-if="form.doctor_id">
                <el-input
                  placeholder="Поиск"
                  ref="searchServices"
                  class="my-kh-search mb-3"
                  size="small"
                  v-model="searchServices"
                ></el-input>
                <el-tree
                  class="filter-tree"
                  :data="relation_free_list"
                  show-checkbox
                  node-key="id"
                  :props="defaultProps"
                  @check-change="selectDoctorService"
                  :filter-node-method="filterNode"
                  ref="items"
                  :expand-on-click-node="true"
                >
                  <span
                    :style="`background: ${data.color}`"
                    style="line-height: 15px"
                    class="custom-tree-node"
                    slot-scope="{ node, data }"
                  >
                    <span
                      style="
                        padding-right: 100px;
                        display: inline-block;
                        white-space: break-spaces;
                        line-height: 15px;
                        font-size: 14px;
                      "
                      >{{ data.name }}</span
                    >
                    <span>
                      <div v-if="data.children.length == 0">
                        {{ data.price | formatMoney }}
                      </div>
                    </span>
                  </span>
                </el-tree>
              </div>
            </el-col>
            <!-- <el-col :xs="24" v-if="user.organization">
              <div class="grid-content">
                <div class="add_patient">
                  <div class="title_cet mb_10">
                    {{ $t("message.contracts_n") }}
                  </div>
                  <div class="radioMarka">
                    <el-select
                      v-model="form.contract_id"
                      :placeholder="$t('message.hidden')"
                      class="selectRoom"
                      filterable
                      clearable
                    >
                      <el-option
                        v-for="item in user.organization.company_contracts"
                        :key="item.id"
                        :label="item.number"
                        :value="item.id"
                      >
                        <span style="float: left">{{ item.number }}</span>
                        <span
                          style="float: right; color: #8492a6; font-size: 13px"
                          >{{ item.end_date | dateFormat }}</span
                        >
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </div>
              <div class="grid-content">
                <div class="add_patient">
                  <div class="radioMarka">
                    <el-checkbox v-model="form.payment_status_free"
                      >{{ $t("message.without_amount") }}
                    </el-checkbox>
                  </div>
                </div>
              </div>
            </el-col> -->
          </template>
          <!-- end el-col -->

          <el-col v-else :xs="24" :sm="16" :md="16" :lg="16" :xl="16">
            <div class="grid-content">
              <div class="rihgr_popups">
                <i class="icon_user"></i>
                <h1>{{ $t("message.select_patient") }}</h1>
                <h6>{{ $t("message.select_patient_or_find") }}</h6>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane :label="$t('message.service')" name="second">
        <el-row class="p20">
          <el-col :xs="24" :sm="15" :md="15" :lg="15" :xl="15">
            <!-- <el-select
              v-model="patientServiceId"
              filterable
              :placeholder="$t('message.start_input_find_service')"
              class="autoInput"
              clearable
            >
              <template v-for="serviceParent in servicesSelected">
                <el-option-group
                  v-if="
                    serviceParent.children && serviceParent.children.length > 0
                  "
                  :key="serviceParent.id"
                  :label="serviceParent.name"
                >
                  <el-option
                    v-for="service in getChildrens(serviceParent.children)"
                    :key="service.id"
                    :label="service.name"
                    :value="service.id"
                  >
                  </el-option>
                </el-option-group>
                <el-option-group
                  v-else-if="
                    serviceParent.is_service == true &&
                    serviceParent.parent_id == null
                  "
                  :key="serviceParent.id"
                >
                  <el-option
                    :label="serviceParent.name"
                    :value="serviceParent.id"
                  >
                  </el-option>
                </el-option-group>
              </template>
            </el-select> -->
          </el-col>
          <!-- end col -->

          <el-col :xs="24" :sm="9" :md="9" :lg="9" :xl="9">
            <!-- <el-button
              class="mb-sm-1"
              style="
                font-size: 14px !important;
                padding: 12px !important;
                margin-left: 10px;
              "
              @click="addService"
              >{{ $t("message.add") }}</el-button
            > -->
            <el-button
              style="font-size: 14px !important; padding: 12px !important"
              @click="drawerPatientService = true"
              >{{ $t("message.add_service") }}</el-button
            >
            <el-button
              v-if="contractServiceList.length != 0 && form.contract_id"
              style="font-size: 14px !important; padding: 12px !important"
              @click="AddContactServices"
              >{{ $t("message.subject_of_agreement") }}</el-button
            >
          </el-col>
          <!-- end col -->

          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <table class="table table-striped table-bordered mt-4">
              <thead>
                <tr>
                  <th>{{ $t("message.pp") }}</th>
                  <th class="wi-50">{{ $t("message.name") }}</th>
                  <th class="wi-7">{{ $t("message.express") }}</th>
                  <th class="wi-7">{{ $t("message.quantity_short") }}</th>
                  <th class="wi-7">{{ $t("message.price2") }}</th>
                  <th class="wi-7">{{ $t("message.discount_percent") }}</th>
                  <th class="wi-7">{{ $t("message.discount_price") }}</th>
                  <th class="wi-7">{{ $t("message.price2") }}</th>
                  <th class="wi-7">{{ $t("message.delete") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(ser, index) in items"
                  :key="'order-create-in-header' + index"
                >
                  <td>{{ index + 1 }}</td>
                  <td>
                    {{ ser.name }}
                  </td>
                  <td>
                    <div class="d-flex">
                      <el-checkbox
                        @input="editRow(ser, $event, 'is_express')"
                        :value="ser.is_express"
                        size="small"
                        v-if="express.length"
                      />
                      <div class="px-2">
                        {{ express.length ? express[0].percent + " %" : "" }}
                      </div>
                    </div>
                  </td>
                  <td>
                    <el-input
                      type="number"
                      id="usr"
                      size="small"
                      step="any"
                      @input="editRow(ser, $event, 'count')"
                      :value="ser.count"
                      :min="1"
                    ></el-input>
                  </td>
                  <td>
                    {{ ser.price | formatMoney }}
                  </td>
                  <td>
                    <el-input-number
                      :disabled="disableDiscount()"
                      type="number"
                      size="small"
                      @change="editRow(ser, $event, 'discount_price')"
                      controls-position="right"
                      :value="discountService(ser.discount_price)"
                      :min="0"
                      :max="100"
                    ></el-input-number>
                  </td>
                  <td>
                    <div
                      v-if="!ser.discount_price || ser.discount_price == null"
                    >
                      <crm-price-input
                        @input="editRow(ser, $event, 'price_of_discount')"
                        v-model="ser.price_of_discount"
                        :old="ser.price_of_discount"
                        size="mini"
                      >
                      </crm-price-input>
                    </div>
                    <div v-else>
                      {{
                        Math.round(
                          (discountService(ser.discount_price) *
                            ser.price *
                            ser.count) /
                            100
                        ) | formatMoney
                      }}
                    </div>
                  </td>
                  <td>
                    <span v-if="ser.discount_price">
                      {{
                        Math.round(
                          ser.count *
                            expressPrice(ser.is_express) *
                            (ser.price -
                              (ser.price *
                                discountService(ser.discount_price)) /
                                100)
                        ) | formatMoney
                      }}
                    </span>
                    <span v-else>
                      {{
                        Math.round(
                          ser.count * expressPrice(ser.is_express) * ser.price -
                            ser.price_of_discount
                        ) | formatMoney
                      }}
                    </span>
                  </td>
                  <td>
                    <el-button
                      class="d-block"
                      type="danger"
                      icon="el-icon-delete"
                      circle
                      @click.native.prevent="deleteRow(ser)"
                    >
                    </el-button>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <th colspan="5">{{ $t("message.total_0") }}</th>
                <th class="w-7">{{ totalDiscount | formatMoney }}</th>
                <th colspan="2">{{ Math.round(totalAmount) | formatMoney }}</th>
              </tfoot>
            </table>
          </el-col>
        </el-row>

        <!-- <el-row :gutter="25" class="p20 my-1">
          <el-col :sm="12">
            <el-form ref="form">
              <div class="title_cet">{{ $t("message.material") }}</div>

              <el-select
                class="autoInput"
                v-model="form.material_id"
                filterable
                clearable
                :placeholder="$t('message.material')"
              >
                <el-option
                  v-for="item in materials"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form>
          </el-col>-->

        <el-row :gutter="25" class="p20">
          <el-col :span="12">
            <el-form ref="form">
              <el-form-item>
                <div class="title_cet">{{ $t("message.diagnos") }}</div>
                <el-input
                  v-model="form.diagnos"
                  :placeholder="$t('message.diagnos')"
                ></el-input>
              </el-form-item>
            </el-form>
          </el-col>
          <el-col :span="12">
            <el-form ref="form">
              <el-form-item>
                <div class="title_cet">
                  {{ $t("message.secondary_doctor") }}
                </div>
                <users
                  @parent-event="parentEvent"
                  :specialty_id="specialty_id"
                  v-model="form.secondary_doctor_id"
                  :doctor_id="form.secondary_doctor_id"
                ></users>
              </el-form-item>
            </el-form>
          </el-col>
          <el-col :span="8">
            <div class="my-100">
              <div class="title_cet">{{ $t("message.insurance_company") }}</div>
              <el-form ref="form" status-icon :model="form" :rules="rules">
                <el-form-item>
                  <el-select
                    class="autoInput"
                    v-model="form.insurance_company_id"
                    filterable
                    clearable
                    :placeholder="$t('message.insurance_company')"
                  >
                    <el-option
                      v-for="item in insuranceCompanies"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-form>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="my-100">
              <div class="title_cet">{{ $t("message.insurance_number") }}</div>
              <el-form ref="form" status-icon :model="form" :rules="rules">
                <el-form-item>
                  <el-input
                    v-model="form.insurance_number"
                    :placeholder="$t('message.insurance_number')"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="my-100">
              <div class="title_cet">{{ $t("message.department") }}</div>
              <el-select
                class="autoInput"
                v-model="form.department_id"
                filterable
                clearable
                :placeholder="$t('message.department')"
              >
                <el-option
                  v-for="item in departments"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>

              <button class="add_pats" @click="drawerDepartment = true">
                {{ $t("message.add_new_department") }}
              </button>
            </div>
          </el-col>

          <el-col :sm="8">
            <div class="my-100">
              <div class="title_cet">{{ $t("message.partner_doctor") }}</div>

              <partner-doctors
                :size="'large'"
                v-model="form.partner_doctor_id"
                :partner_clinic_id="form.partner_clinic_id"
              ></partner-doctors>

              <button class="add_pats" @click="drawerPartnerDoctor = true">
                {{ $t("message.add_new_partner_doctor") }}
              </button>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="25" class="p20">
          <el-col
            :sm="8"
            v-for="orderColumn in orderColumns"
            :key="orderColumn.id"
          >
            <div class="my-100">
              <div class="title_cet">{{ orderColumn.name }}</div>
              <el-form ref="form" status-icon :model="form" :rules="rules">
                <el-form-item>
                  <el-input v-model="attributes[orderColumn.name]"></el-input>
                </el-form-item>
              </el-form>
            </div>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>

    <el-drawer
      class="popups_title"
      :append-to-body="true"
      :visible.sync="drawerCreatePatient"
      direction="rtl"
      size="60%"
      :wrapperClosable="false"
    >
      <CreatePatient
        @c-close="closeDrawerPatient"
        :reopened="drawerCreatePatient"
        drawer="drawerCreatePatient"
      >
      </CreatePatient>
    </el-drawer>

    <el-drawer
      class="popups_title"
      @open="reopenUpdate = true"
      @close="reopenUpdate = false"
      :append-to-body="true"
      :visible.sync="drawerUpdatePatient"
      direction="rtl"
      size="60%"
      :wrapperClosable="false"
    >
      <UpdatePatient
        @c-close="closeDrawerUpdatePatient"
        :reloadModel="reopenUpdate"
        :patient="selectedPatient"
        :open="drawerUpdatePatient"
        drawer="drawerUpdatePatient"
      ></UpdatePatient>
    </el-drawer>

    <el-drawer
      class="popups_title"
      :append-to-body="true"
      :visible.sync="drawerPartnerDoctor"
      direction="rtl"
      size="80%"
      :wrapperClosable="false"
    >
      <AddPartnerDoctor
        @c-close="closeDrawerAddPartnerDoctor"
      ></AddPartnerDoctor>
    </el-drawer>

    <el-drawer
      class="popups_title"
      :append-to-body="true"
      :visible.sync="drawerPartnerClinic"
      direction="rtl"
      size="80%"
      :wrapperClosable="false"
    >
      <AddPartnerClinic
        @c-close="closeDrawerAddPartnerDoctor"
      ></AddPartnerClinic>
    </el-drawer>

    <el-drawer
      class="popups_title"
      :append-to-body="true"
      :visible.sync="drawerDepartment"
      direction="rtl"
      size="50%"
      :wrapperClosable="false"
    >
      <CreateDepartment
        @c-close="closeDrawerAddPartnerDoctor"
      ></CreateDepartment>
    </el-drawer>

    <el-drawer
      class="popups_title"
      :wrapperClosable="false"
      :append-to-body="true"
      :visible.sync="drawerPatientService"
      direction="rtl"
      size="85%"
      :ref="serviceDrawer.create.name"
      @opened="drawerOpened(serviceDrawer.create.component)"
      @closed="drawerClosed(serviceDrawer.create.component)"
    >
      <CreatePatientService
        :ref="serviceDrawer.create.component"
        :driver="drawerPatientService"
        :doctor_id="doctor_id"
        @items="itemsChanged"
        @owner_branch_id="getOwnerBranch"
        @c-close="closeDrawerPatientService"
      ></CreatePatientService>
    </el-drawer>
    <el-drawer
      title="Изменить тег"
      :visible.sync="drawerShow"
      size="70%"
      :wrapperClosable="false"
      :drawer="drawerShow"
      @open="reopenShow = true"
      :append-to-body="true"
      @close="reopenShow = false"
    >
      <div>
        <crm-show
          :reloadModel="reopenShow"
          @open="reopenShow = true"
          @c-close="reloadIfChanged('drawerShow')"
          @closed="emptyModel"
        ></crm-show>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";
import order from "@/utils/mixins/order";
import users from "@/components/selects/user_list";
import CrmShow from "./crm-show";
import partnerDoctors from "@/components/inventory-select/partner-doctor";
import drawer from "@/utils/mixins/drawer";
import CrmPriceInput from "@/components/crm/include/crm-price-input";

export default {
  mixins: [order, drawer],
  props: ["currentPatient"],
  components: {
    CrmShow,
    users,
    partnerDoctors,
    CrmPriceInput,
  },
  data() {
    return {
      RoomName: "",
      RoomNumber: "",
      old_save: false,
      specialty_id: null,
      old_order: {},
      infoOldSelected: false,
      drawerShow: false,
      reopenShow: false,
      saveBtn: true,
      printModel: {},
      owner_branch_id: null,
      express_check: false,
      reopenUpdate: false,
      reloadList: false,
      room: null,
      doctor_id: null,
      drawerDepartment: false,
      attributes: {},
      searchServices: "",
      defaultProps: {
        children: "children",
        label: "label",
        price: "price",
      },
      serviceDrawer: {
        create: {
          name: "create",
          status: false,
          component: "componentDrawerCreate",
        },
      },
    };
  },
  watch: {
    searchServices(val) {
      this.$refs.items.filter(val);
    },
    search() {
      this.infoOldSelected = false;
    },
    open: {
      handler: function (newVal, oldVal) {
        if (newVal === true) {
          this.setNewOrderData();
        }
      },
      deep: true,
      immediate: true,
    },
    conditions: {
      handler: function (newVal, oldVal) {
        if (newVal) {
          this.form.condition_id = this.conditions[0].id;
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      orderColumns: "orderColumn/list",
      insuranceCompanies: "insuranceCompany/list",
      contractServiceList: "contracts/contract_services",
      // model: "orders/relation_model",
      specialties: "specialties/list",
      workTimes: "workTimes/inventory",
      rules: "orders/rules",
      express: "expresses/list",
    }),
  },
  mounted() {
    if (this.workTimes && this.workTimes.length === 0) this.setworkTimes();
    if (this.conditions && this.conditions.length === 0) this.loadConditions();
    const order_column_query = {
      get_actives: true,
      place: "is_register",
      per_page: 1000000,
    };
    this.getOrderColumns(order_column_query);
    this.getInsuranceCompany();
    this.getSpecialties();
    if (this.express && this.express.length === 0) this.loadExpress();
  },
  created() {
    this.fetchData();
  },
  methods: {
    ...mapActions({
      getInsuranceCompany: "insuranceCompany/index",
      getOrderColumns: "orderColumn/index",
      updateSetlist: "services/updateSetlist",
      setworkTimes: "workTimes/inventory",
      loadExpress: "expresses/index",
      editService: "services/show",
      getSpecialties: "specialties/index",
      servicesRealtionList: "services/relationList",
      contractServices: "contracts/contractServices",
      discountPatient: "discountPatients/show",
      // empty_conclusions: "conclusions/empty",
      showModel: "conclusions/show",
      save: "orders/store",
      update: "orders/update",
      saveList: "services/setlist",
    }),
    getOwnerBranch(val) {
      this.owner_branch_id = val;
    },
    filterDepartment(val) {
      const query = { partner_clinic_id: val };
      this.loadDepartments(query).then((res) => {
        if (!res.data.departments.data.length) this.form.department_id = null;
      });
    },
    filterNode(value, data) {
      if (!value) return true;
      let dd = data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
      return dd;
    },
    selectDoctorService(val) {
      let item = this.$refs.items.getCheckedNodes();
      let arr = [];
      this.emptyList();
      for (const i in item) {
        if (item[i]) {
          const element = item[i];
          if (element.children.length == 0) {
            arr.push(element);
          }
        }
      }
      this.updateSetlist(JSON.parse(JSON.stringify(arr))).then((res) => {
        this.items = JSON.parse(JSON.stringify(this.list));
      });
    },
    addClass() {
      this.infoOldSelected = true;
    },
    parentEvent(value) {
      this.servicesFreeList({
        selected_relation: true,
        only_child: true,
        doctor_id: value,
      });
      this.emptyList();
      // this.items = [];
      this.doctor_id = value;
    },
    async show(model) {
      this.$loadingCursor("wait");
      await this.showModel(model.id)
        .then((res) => {
          this.$loadingCursor("default");
          this.drawerShow = true;
        })
        .catch((err) => {
          this.$loadingCursor("default");
        });
    },
    disableDiscount() {
      if (
        this.discountPatients.length &&
        this.discountPatients[0].discount != 0 &&
        this.discountPatients[0].status
      ) {
        return true;
      } else return false;
    },

    expressPrice(is_express) {
      if (is_express) {
        return 1 + this.express[0].percent / 100;
      } else {
        return 1;
      }
    },
    discountService(sevice_discount) {
      if (
        this.discountPatients.length &&
        this.discountPatients[0].discount != 0 &&
        this.discountPatients[0].status
      ) {
        this.items.forEach((element) => {
          element.discount_price = this.discountPatients[0].discount;
        });
        return this.discountPatients[0].discount;
      } else return sevice_discount;
    },

    itemsChanged(val) {
      this.items = JSON.parse(JSON.stringify(val));
    },
    fetchData() {
      this.loadingData = true;
      const query = { relation: true };
      this.servicesRealtionList(query)
        .then((res) => {
          this.loadingData = false;
        })
        .catch((err) => {});
    },
    setNewOrderData() {
      this.form = JSON.parse(JSON.stringify(this.model));
      this.form.day = new Date();
    },
    submit(close) {
      this.changeWaiting(true);
      this.form.patient_id = this.user.id;
      this.form.services = this.items;
      this.form.owner_branch_id = this.owner_branch_id;
      this.form.order_attributes = this.attributes;
      this.save(this.form)
        .then((res) => {
          this.old_save = true;
          this.old_order = res.data.order;
          this.changeWaiting();
          this.saveBtn = false;
          this.printModel = res.data ? res.data.order : null;
          this.$notify({
            title: "Успешно",
            type: "success",
            offset: 130,
            message: res.message,
          });
        })
        .catch((err) => {
          this.changeWaiting();
          this.ifValidationError(err);
        });
    },
    updatingOrder(close) {
      this.changeWaiting(true);
      this.form.id = this.old_order.id;
      this.form.patient_id = this.user.id;
      this.form.services = this.items;

      this.update(this.form)
        .then((res) => {
          this.changeWaiting();
          this.saveBtn = false;
          this.printModel = res.data ? res.data.order : null;
          this.$notify({
            title: "Успешно",
            type: "success",
            offset: 130,
            message: res.message,
          });
        })
        .catch((err) => {
          this.changeWaiting();
          this.ifValidationError(err);
        });
    },

    tabEvent(event) {
      if (
        event.name == "second" &&
        this.form.contract_id &&
        this.contractServiceList.length == 0
      ) {
        this.contractServices({
          id: this.form.contract_id,
          contract_service: true,
        })
          .then((res) => {})
          .catch((err) => {});
      }
    },
    AddContactServices() {
      this.saveList(this.contractServiceList.services)
        .then((res) => {})
        .catch((err) => {});
    },
    closeForm() {
      this.old_order = {};
      this.old_save = false;
      this.empty();
      this.$emit("c-close", { reload: false, drawer: this.drawer });
    },
    resetForm() {
      this.setNewOrderData();
      this.user = {};
      this.noUser();
      this.emptySearch();
      this.emptyList();
      this.old_order = {};
      this.old_save = false;
      this.search = "";
      this.saveBtn = true;
      this.latestServices.order_services = [];
      this.discountPatients = [];
      this.items = [];
    },
    empty() {
      this.activeTab = "first";
      this.emptyList();
      this.emptySearch();
      this.noUser();
      this.user = null;
      this.patientServiceId = "";
      this.search = "";
      this.saveBtn = true;
      this.latestServices.order_services = [];
      this.discountPatients = [];
      this.items = [];
    },
    listChanged() {
      this.reloadList = true;
    },
    parent() {
      return this.$parent.$parent;
    },
    emptyModel() {
      this.empty_conclusions();
    },
  },
};
</script>
<style lang="scss">
.tableKa .el-table_1_column_7 .cell {
  display: block !important;
}

.bemor_uslugalar {
  text-decoration: none;
  list-style: none;
  margin: 0px;
  padding: 0px;

  li {
    border-bottom: 1px solid #dcdfe6;
    line-height: 19px;
    padding: 5px 0;
  }

  div {
    font-weight: 600;
  }

  span {
    text-align: right;
    width: 100%;
    display: block;
  }

  .col__red span {
    color: red;
  }
}

.ow_hiddin {
  height: 50vh;
  overflow-y: scroll;
}

.ow_hiddin::-webkit-scrollbar {
  width: 8px;
}

.ow_hiddin::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: #fff;
}

.ow_hiddin::-webkit-scrollbar-thumb {
  background-color: #1f75a8;
  border-radius: 20px;
}

.my_add_pats {
  margin: 10px 0 20px 0;
}

.new_room {
  display: flex;
  width: 100%;
  align-items: center;
  height: 50px;
  /* background: #dcdfe6; */
  border-bottom: 1px solid #dcdfe6;
}

.room_number_title {
  margin-left: 10px;
  font-weight: 600;
}

.my-100 {
  .el-select {
    width: 100%;
  }

  .add_pats {
    margin-top: 0px;
  }
}
</style>