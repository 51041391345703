<template>
  <div class="top-collectors chart-card">
    <div class="top-collectors__header chart-card__header">
      <h2 class="py-2">{{ title }}</h2>
      <!-- <el-dropdown trigger="click">
        <span>
          Views All<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item icon="el-icon-plus">Action 1</el-dropdown-item>
          <el-dropdown-item icon="el-icon-circle-plus"
            >Action 2</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown> -->
    </div>
    <table style="width: 100%" v-if="doctor" class="top-collectors-table">
      <div class="tbody">
        <tr v-for="(item, key) in doctor.top_doctors" :key="key + 'avatar'">
          <td>
            <div>
              <el-avatar
                size="medium"
                :src="`/img/doctor_icons/${key + 1}.png`"
              ></el-avatar>
              {{ userName(item) }}
            </div>
          </td>
          <td>
            <el-progress :stroke-width="8" :percentage="percentage(item)"></el-progress>
          </td>
          <td>
            <div>
              <h6>{{ item.bonus_amount | formatMoney }}</h6>
              <span>{{ item.paid_amount | formatMoney }}</span>
            </div>
          </td>
        </tr>
      </div>
    </table>
  </div>
</template>

<script>
export default {
  props: ["title", "doctor"],

  methods: {
    userName(item) {
      if (item.user) return item.user.name + " " + item.user.surname;
      if (item.partner_doctor) return item.partner_doctor.name;
      return "";
    },
    percentage(item) {
      return parseFloat(
        ((item.bonus_amount * 100) / this.doctor.sum_doctor_bonus).toFixed(2)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.chart-card {
  height: 100%;
}
.tbody {
  max-height: 350px;
}
.top-collectors__header {
  padding: 12px 24px;
}

.top-collectors-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 8px;

  .tbody {
    // max-height: 480px;
    overflow-y: auto;

    tr {
      vertical-align: middle;
      height: 80px;
    }
  }

  tr {
    td {
      border-bottom: 1px solid #eee;
      padding: 0 8px;

      &:first-child {
        padding-left: 24px;
        width: 200px;
        color: #222;
        font-weight: 500;

        div {
          display: inline-flex;
          align-items: center;
          gap: 12px;

          .el-avatar {
            flex-shrink: 0;
          }
        }
      }

      &:nth-child(2) {
        width: 300px;
      }

      &:last-child {
        padding-right: 24px;
        // text-align: right;
        width: 150px;

        h6 {
          margin: 0;
          color: #222;
        }

        span {
          color: #10b981;
          font-size: 14px;
        }
      }
    }
  }
}
</style>
