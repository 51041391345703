export const rules = {
    detail: [
        { required: true, message: 'Пожалуйста, введите Койка ', trigger: 'blur' },
    ],
    products: [
        { required: true, message: 'Пожалуйста, введите лекарства', trigger: 'blur' },
    ],
    dailyTreatmentNames: [
        { required: true, message: 'Пожалуйста, введите лекарства ', trigger: 'blur' },
    ]
};
