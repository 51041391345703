import { actions } from './bunk/actions'
import { getters } from './bunk/getters'
import { state } from './bunk/state'
import { mutations } from './bunk/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}
