import Layout from '@/layouts'

const reportLimit = {
  path: '/reportLimit',
  component: Layout,
  name: 'reportLimit',
  redirect: '/reportLimit/index',
  children: [
    {
      path: '/reportLimit/index',
      component: () => import('@/views/reportLimit/index'),
      name: 'reportLimit/index',
      meta: { title: 'Типы расхода'}
    },
  ]
}

export default reportLimit