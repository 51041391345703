import request from '../utils/request'


export function index(params) {
  	return request({
  	  url: '/hospitalPatients',
  	  method: 'get',
        params
  	})
  }
  export function getLastId(params) {
    return request({
      url: '/hospitalPatients/getLastId',
      method: 'get',
      params
    })
  } 
  export function inventory(params) {
    return request({
      url: '/hospitalPatients/inventory',
      method: 'get',
      params
    })
  }
  
export function show(id) {
    return request({
      url: `/hospitalPatients/${id}`,
      method: 'get'
    })
  }

  export function store(data) {
    return request({
      url: '/hospitalPatients',
      method: 'post',
      data
    })
  }

  export function update(data) {
    return request({
      url: `/hospitalPatients/${data.id}`,
      method: 'put',
      data
    })
  }

  export function destroy(id) {
    return request({
      url: `/hospitalPatients/${id}`,
      method: 'delete',
    })
  }
