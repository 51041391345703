import { actions } from './paymentReport/actions'
import { getters } from './paymentReport/getters'
import { state } from './paymentReport/state'
import { mutations } from './paymentReport/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}