import request from '../utils/request'


export function index(params) {
  return request({
    url: '/typeHospitalizations',
    method: 'get',
      params
  })
}

export function inventory(params) {
  return request({
    url: '/typeHospitalizations/inventory',
    method: 'get',
    params
  })
}

  

export function show(id) {
    return request({
      url: `/typeHospitalizations/${id}`,
      method: 'get'
    })
  }

  export function store(data) {
    return request({
      url: '/typeHospitalizations',
      method: 'post',
      data
    })
  }

  export function update(data) {
    return request({
      url: `/typeHospitalizations/${data.id}`,
      method: 'put',
      data
    })
  }

  export function destroy(id) {
    return request({
      url: `/typeHospitalizations/${id}`,
      method: 'delete',
    })
  }
