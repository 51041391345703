export const getters = {
    list: state => state.list,
    reports: state => state.reports,
    columns: state => state.columns,
    score: state => state.score,
    model: state => state.model,
    filter: state => state.filter,
    pagination: state => state.pagination,
    sort: state => state.sort,
    rules: state => state.rules
};