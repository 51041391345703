import { i18n } from '@/utils/i18n';

export const columns = {
    id: {
        show: true,
        title: "№",
        sortable: true,
        column: 'id'
    },
    patient_id: {
        show: true,
        title: i18n.t('message.patient'),
        sortable: true,
        column: 'patient_id'
    },
    total_price: {
        show: true,
        title: i18n.t('message.total_amount'),
        sortable: true,
        column: 'total_price'
    },
    paid_price: {
        show: true,
        title: i18n.t('message.paid'),
        sortable: false,
        column: 'paid_price'
    },
    payment_type: {
        show: true,
        title: i18n.t('message.payment_type'),
        sortable: false,
        column: 'payment_type'
    },
    created_at: {
        show: true,
        title: i18n.t('message.date'),
        sortable: true,
        column: 'created_at'
    },
    // updated_at: {
    //     show: false,
    //     title: i18n.t('message.update_date'),
    //     sortable: true,
    //     column: 'updated_at'
    // },
    settings: {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    }
};
