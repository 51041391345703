export const rules = {
    patient_id: [
        { required: true, message: 'Пожалуйста, выберите пациент ', trigger: 'change' },
    ],
    room_id: [
        { required: true, message: 'Пожалуйста, выберите комната ', trigger: 'change' },
    ],
    condition_id: [
        { required: true, message: 'Пожалуйста, выберите состояние ', trigger: 'change' },
    ],
    day: [
        { required: true, message: 'Пожалуйста, выберите день ', trigger: 'change' },
    ],
    partner_clinic: [
        { required: true, message: 'Пожалуйста, выберите клиника ', trigger: 'change' },
    ],
};
