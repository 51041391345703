import {
  index,
  show,
  store,
  update,
  destroy,
  destroyItem,
  receive,
  warehouseState
} from '../../../api/procurements';

export const actions = {
  index({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      index(payload)
        .then(response => {
          commit('SET_LIST', response.data.data.procurements);
          commit('UPDATE_PAGINATION', {
            key: 'total',
            value: response.data.data.procurements.total
          });
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  show({
    commit
  }, id) {
    return new Promise((resolve, reject) => {
      show(id)
        .then(response => {
          commit('SET_MODEL', response.data.data.procurement);
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  store({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      store(payload)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  update({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      update(payload)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  destroy({
    commit
  }, id) {
    return new Promise((resolve, reject) => {
      destroy(id)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  destroyItem({
    commit
  }, id) {
    return new Promise((resolve, reject) => {
      destroyItem(id)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  warehouseState({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      warehouseState(payload)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  receive({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      receive(payload)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateSort({
    commit
  }, sort) {
    commit('SET_SORT', sort);
  },
  updateFilter({
    commit
  }, filter) {
    commit('SET_FILTER', JSON.parse(JSON.stringify(filter)));
  },
  updateColumn({
    commit
  }, column) {
    commit('UPDATE_COLUMN', column);
  },
  updatePagination({
    commit
  }, pagination) {
    commit('UPDATE_PAGINATION', pagination);
  },
  refreshData({
    commit
  }) {
    return new Promise((resolve, reject) => {
      commit('REFRESH');
      resolve();
    });
  },
  empty({
    commit
  }) {
    return new Promise((resolve, reject) => {
      commit('EMPTY_MODEL');
      resolve();
    });
  }
};