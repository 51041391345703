import { actions } from './patientResidenceReport/actions'
import { getters } from './patientResidenceReport/getters'
import { state } from './patientResidenceReport/state'
import { mutations } from './patientResidenceReport/mutations'

export default {
    namespaced: true,
    actions,
    mutations,
    state,
    getters
}