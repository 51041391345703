
export const state = {
    schedulePatients: [],
    stasionaryPatients: [],
    paymentsSum: [],
    expences: [],
    items: [],
    // payment_chart: [],
    // cost_chart: [],
    contractsCount: [],
    formTemplates: [],
    patientScheduleCount: [],
    stasionaryPayments: [],
    warehouseCount: [],
    reportClinic:[],
    reportDoctor:[],
    number_of_orders_today: [],
    number_of_orders_month: [],
    doctor: [],
    partner_doctor: [],
    top_services: [],
    gender: [],
    hospital: [],
    order_chart: [],
    patient_chart: [],
    age: [],
};
