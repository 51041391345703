export const rules = {
    number: [
        { required: true, message: 'Пожалуйста, введите Договор № ', trigger: 'blur' },
    ],
    begin_date: [
        { required: true, message: 'Пожалуйста, введите Дата договора ', trigger: 'blur' },
    ],
    organization_id: [
        { required: true, message: 'Пожалуйста, выберите Организации ', trigger: 'blur' },
    ],
};
