export const filter = {
    id: null,
    service_id: null,
    doctor_id: null,
    patient_id: '',
    form_template_id: null,
    order_id: null,
    search: "",
    created_at: "",
    updated_at: "",
};
