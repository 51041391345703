import { i18n } from '@/utils/i18n';

export const columns = {
    id: {
        show: true,
        title: "№",
        sortable: true,
        column: 'id'
    },

    bunk: {
        show: true,
        title: i18n.t('message.bunk'),
        sortable: true,
        column: 'bunk'
    },
    corps_id: {
        show: true,
        title: i18n.t('message.corps'),
        sortable: true,
        column: 'corps_id'
    },
    class_id: {
        show: true,
        title: i18n.t('message.class'),
        sortable: true,
        column: 'class_id'
    },
    floor_id: {
        show: true,
        title: i18n.t('message.floor'),
        sortable: true,
        column: 'floor_id'
    },
    hospitalRoom_id: {
        show: true,
        title: i18n.t('message.room'),
        sortable: true,
        column: 'hospitalRoom_id'
    },

    created_at: {
        show: true,
        title: i18n.t('message.date'),
        sortable: true,
        column: 'created_at'
    },
    updated_at: {
        show: false,
        title: i18n.t('message.update_date'),
        sortable: true,
        column: 'updated_at'
    },
    settings: {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    }
};
