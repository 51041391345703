export const filter = {
    id: "",
    number: "",
    parent_id: "",
    organization_id: "",
    begin_date: "",
    search: "",
    end_date: "",
    sum: "",
    remainder: "",
    status_id: "",
    comment: "",
    created_at: "",
    updated_at: "",
};
