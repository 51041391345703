import request from './../utils/request';

export function index(params) {
  return request({
    url: '/procurements',
    method: 'GET',
    params
  });
}

export function show(id) {
  return request({
    url: '/procurements/' + id,
    method: 'GET'
  });
}

export function store(data) {
  return request({
    url: '/procurements',
    method: 'POST',
    data
  });
}

export function update(data) {
  return request({
    url: '/procurements/' + data.id,
    method: 'PUT',
    data
  });
}

export function destroy(id) {
  return request({
    url: '/procurements/' + id,
    method: 'DELETE'
  });
}

export function destroyItem(id) {
  return request({
    url: '/procurements/items/' + id,
    method: 'DELETE'
  });
}

export function receive(payload) {
  return request({
    url: '/procurements/' + payload.id + '/receive',
    method: 'POST',
    data:{
      items: payload.items
    }
  });
}

export function warehouseState(payload) {
  return request({
    url: '/procurements/' + payload.id + '/warehouseState',
    method: 'GET'
  });
}