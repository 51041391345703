import {sort} from "../properties/sort";
import {filter} from "./properties/filter";
import {pagination} from "../properties/pagination";
import {model} from "./properties/model";
import { i18n } from '@/utils/i18n';

export const mutations = {
    SET_LIST: (state, conditions) => {
        state.list = conditions
    },
    SET_FILTER: (state,filter) => (state.filter = filter),
    SET_PAGINATION: (state,pagination) => (state.pagination = pagination),
    SET_SORT: (state,sort) => (state.sort = sort),
    SET_CONTRACT_SERVICES_MODEL: (state,services) => (state.contract_services = services),
    SET_MODEL: (state,model) => {
        state.model.id = model.id;
        state.model.number = model.number;
        state.model.parent_id = model.parent_id;
        state.model.begin_date = model.begin_date;
        state.model.end_date = model.end_date;
        state.model.sum = model.sum;
        state.model.remainder = model.remainder;
        state.model.organization_id = model.organization_id;
        state.model.organization = model.organization ? model.organization : [];
        state.model.transactions = model.transactions ? model.transactions : [];
        state.model.orders = model.orders ? model.orders : [];
        state.model.status_id = model.status_id;
        state.model.comment = model.comment;
        state.model.services = model.services ? model.services : [];
        state.model.created_at = model.created_at;
        state.model.updated_at = model.updated_at;
    },
    UPDATE_COLUMN: (state, obj) => {
        state.columns[obj.key].show = obj.value
    },
    UPDATE_SORT: (state, sort) => {
        state.sort[sort.column] = sort.order
    },
    UPDATE_PAGINATION: (state, pagination) => {
        state.pagination[pagination.key] = pagination.value
    },
    EMPTY_MODEL: (state) => {
        state.model = JSON.parse( JSON.stringify( model ));
    },
    REFRESH: (state) => {
        state.filter = JSON.parse( JSON.stringify( filter ));
        state.sort = JSON.parse( JSON.stringify( sort ));
        state.pagination = JSON.parse( JSON.stringify( pagination ));
    }, 
    UPDATE_LANG_COLUMN: (state) => {
        state.columns.id.title = "№";
        state.columns.number.title =  i18n.t('message.contracts_n');
        state.columns.parent_id.title = i18n.t('message.contract_parent');
        state.columns.begin_date.title = i18n.t('message.date_aggrement');
        state.columns.end_date.title = i18n.t('message.term_aggrement');
        state.columns.sum.title = i18n.t('message.price_aggrement');
        state.columns.remainder.title = i18n.t('message.remainder');
        state.columns.orders_amount.title = i18n.t('message.order_amount');
        state.columns.organization_id.title = i18n.t('message.organization');
        state.columns.status_id.title = i18n.t('message.status');
        state.columns.comment.title = i18n.t('message.comment');
        state.columns.created_at.title = i18n.t('message.date');
        state.columns.updated_at.title = i18n.t('message.update_date');
        state.columns.print.title = i18n.t('message.printing');
        state.columns.settings.title = i18n.t('message.settings');
    }
};
