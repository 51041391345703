<template>
  <el-select
    :value="selected"
    @input="dispatch"
    :placeholder="plc || 'Класс'"
    filterable
    clearable
 
  >
    <el-option v-for="item in classses" :key="item.id" :label="item.name" :value="item.id"></el-option>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import inventory from '@/utils/mixins/components/inventory';

export default {
  mixins: [inventory],
  props: ['classs_id'],
  mounted() {
    if (this.classses && this.classses.length === 0) {
      this.getInventory();
    }
  },
  watch: {
    classs_id:{
       handler: function (val, oldVal) { 
          this.selected = val;
          this.$emit("input", this.selected);
       },
       deep: true,
       immediate: true
    }
  },
  computed: {
    ...mapGetters({
      classses: 'classses/inventory',
    }),
  },
  methods: {
    ...mapActions({
      getInventory: 'classses/inventory',
    })
  },
};
</script>
