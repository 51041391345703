export const model = {
    id: null,
    name: '',   
    mfo: '',   
    currency_id: '',   
    number: '',   
    balance: '',   
    outgoing: '',    
    created_at: '',
    updated_at: ''
};
