import Layout from "@/layouts";

const invoice = {
  path: "/invoiceInsurance",
  component: Layout,
  name: "invoiceInsurance",
  redirect: "/invoiceInsurance/index",
  children: [
    {
      path: "/invoiceInsurance/index",
      component: () => import("@/views/invoiceInsurance/index"),
      name: "invoiceInsurance.index",
      meta: { title: "Счет-фактуры" },
    },
  ],
};

export default invoice;
