export const rules = {
    name: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
    slug: [
        { required: true, message: 'Пожалуйста, введите url ', trigger: 'blur' },
    ],
    parent_id: [
        { required: true, message: 'Пожалуйста, введите url ', trigger: 'blur' },
    ],
};
