import { actions } from './category/actions';
import { getters } from './category/getters';
import { state } from './category/state';
import { mutations } from './category/mutations';

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions
};