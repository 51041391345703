import { actions } from './procurement/actions';
import { getters } from './procurement/getters';
import { state } from './procurement/state';
import { mutations } from './procurement/mutations';

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions
};