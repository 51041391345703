<template>
  <el-select
    :value="selected"
    @input="dispatch"
    @change="emitParent"
    :placeholder="plc || $t('message.district')"
    filterable
    clearable
    remote
    :remote-method="searchdistrictsWithLoad"
  >
    <el-option
      v-for="item in filtered"
      :key="item.id"
      :label="item.name"
      :value="item.id"
    ></el-option>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import inventory from "@/utils/mixins/components/inventory";

export default {
  mixins: [inventory],
  props: ["city_id", "state_id", "id"],
  mounted() {
    if (this.districts && this.districts.length === 0) {
      this.getInventory();
    }
  },
  watch: {
    id: {
      handler: function () {
        setTimeout(() => {
          this.selected = this.id;
        });
      },
      deep: true,
      immediate: true,
    },
    district_id: {
      handler: function (val, oldVal) {
        this.selected = val;
        this.$emit("input", this.selected);
      },
      deep: true,
      immediate: true,
    },
    state_id: function (newVal, oldVal) {
      if (
        newVal != oldVal &&
        !(
          typeof this.filtered.find((o) => o.city_id == this.city_id) ==
          "object"
        )
      ) {
        this.selected = null;
        this.$emit("input", this.selected);
      }
    },
    city_id: function (newVal, oldVal) {
      if (
        newVal != oldVal &&
        !(typeof this.filtered.find((o) => o.city_id == newVal) == "object")
      ) {
        this.getInventory({ city_id: newVal });
        this.selected = null;
        this.$emit("input", this.selected);
      }
    },
  },
  computed: {
    ...mapGetters({
      districts: "districts/inventory",
    }),
    filtered: function () {
      if (this.city_id) {
        return this.districts.filter((o) => {
          return this.city_id == o.city_id;
        });
      } else {
        return this.districts;
      }
    },
  },
  methods: {
    ...mapActions({
      getInventory: "districts/inventory",
    }),
    emitParent(val) {
      let district = this.districts.find((o) => {
        return val == o.id;
      });

      if (district) this.$emit("city_id", district.city_id);
    },
    searchdistricts: _.debounce(function (val) {
      this.getInventory({ search: val, region_id: this.region_id });
    }, 500),
    searchdistrictsWithLoad(val) {
      this.searchdistricts(val);
    },
  },
};
</script>
