import request from '../utils/request'


export function index(params) {
  	return request({
  	  url: '/invoices',
  	  method: 'get',
        params
  	})
  }
 
  export function pay(data) {
    return request({
      url: `/invoice/pay/${data.id}`,
      method: 'put',
      data
    })
  }

  export function excel(params) {
    return request({
        url: "/invoice/excel",
        method: "get",
        responseType: 'blob', 
        params       
    });
}

export function show(id) {
    return request({
      url: `/invoices/${id}`,
      method: 'get'
    })
  }

  export function store(data) {
    return request({
      url: '/invoices',
      method: 'post',
      data
    })
  }

  export function update(data) {
    return request({
      url: `/invoices/${data.id}`,
      method: 'put',
      data
    })
  }

  export function destroy(id) {
    return request({
      url: `/invoices/${id}`,
      method: 'delete',
    })
  }
