import { i18n } from '@/utils/i18n';

export const columns = {
    id: {
        show: true,
        title: "№",
        sortable: true,
        column: 'id'
    },
    name: {
        show: true,
        title: i18n.t('message.name'),
        sortable: false,
        column: 'name'
    },
    date: {
        show: true,
        title: i18n.t('message.date_0'),
        sortable: false,
        column: 'date'
    },
    amount: {
        show: true,
        title: i18n.t('message.amount'),
        sortable: false,
        column: 'amount'
    },
    settings: {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    }
};
