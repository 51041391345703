<template>
  <el-select
    :value="selected"
    @input="dispatch"
    :placeholder="plc || 'Partner Doctor'"
    filterable
    clearable
    :size="size"
  >
    <el-option v-for="item in partnerDoctor" :key="item.id" :label="item.name" :value="item.id"></el-option>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import inventory from '@/utils/mixins/components/inventory';

export default {
  mixins: [inventory],
 
  props: ['partner_doctor_id'],
  mounted() {
    if (this.partnerDoctor && this.partnerDoctor.length === 0) {
      this.getInventory({ column: 'name', order: 'asc'});
    }
  },
  watch: {
   
  },
  computed: {
    ...mapGetters({
      partnerDoctor: 'partnerDoctor/inventory',
    }),
  },
  methods: {
    ...mapActions({
      getInventory: 'partnerDoctor/inventory',
     
    })
  },
 
};
</script>
