import request from '../utils/request'

export function index(params) {
    return request({
        url: '/printlogo',
        method: 'get',
        params
    })
}

export function inventory(params) {
    return request({
        url: '/printlogo/inventory',
        method: 'get',
        params
    })
}

export function show(id) {
    return request({
        url: `/printlogo/${id}`,
        method: 'get'
    })
}

export function store(data) {
    return request({
        url: '/printlogo',
        method: 'post',
        data
    })
}

export function update(data) {
    return request({
        url: `/printlogo/${data.id}`,
        method: 'post',
        data
    })
}

export function destroy(id) {
    return request({
        url: `/printlogo/${id}`,
        method: 'delete',
    })
}

export function deleteImage(data) {
    return request({
        url: '/printlogo/images/delete',
        method: 'post',
        data
    })
}

