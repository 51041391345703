import { actions } from './region/actions'
import { getters } from './region/getters'
import { state } from './region/state'
import { mutations } from './region/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}
