import { i18n } from '@/utils/i18n';

export const columns = {
    id: {
        show: true,
        title: "№",
        sortable: true,
        column: 'id'
    },
    order_id: {
        show: false,
        title:  i18n.t('message.order_id'),
        sortable: true,
        column: 'order_id'
    },
    comment: {
        show: false,
        title:  i18n.t('message.comment'),
        sortable: true,
        column: 'comment'
    },
    patient: {
        show: false,
        title:  i18n.t('message.patient'),
        sortable: true,
        column: 'patient'
    },
    payment_type_id: {
        show: true,
        title: i18n.t('message.payment_type') ,
        sortable: true,
        column: 'payment_type_id'
    },
    type: {
        show: true,
        title: i18n.t('message.type') ,
        sortable: true,
        column: 'type'
    },
    price: {
        show: true,
        title:  i18n.t('message.amount'),
        sortable: true,
        column: 'price'
    },
    date: {
        show: true,
        title:  i18n.t('message.date_0'),
        sortable: true,
        column: 'date'
    },
    created_at: {
        show: true,
        title: i18n.t('message.date'),
        sortable: true,
        column: 'created_at'
    },
};
