import { sort } from './../properties/sort';
import { pagination } from './../properties/pagination';
import { filter } from './properties/filter';
import { model } from './properties/model';
import { i18n } from '@/utils/i18n';

export const mutations = {
  SET_LIST: (state, data) => {
    state.list = data.hospitalization_services;
    state.total_amount = data.total_amount;
  },
  SET_FILTER: (state, filter) => {
    state.filter = filter;
  },
  
  SET_SORT: (state, sort) => {
    state.sort = sort;
  },
  SET_SERVICES: (state, services) => {
    state.patient_services = services;
  },
  SET_MODELL: (state, modell) => {
    state.modell = modell;
  },
  SET_SERVICE: (state, service) => {
    state.modell.service_id = service.id;
    state.modell.count = 1;
    state.modell.discount = service.discount_price;
    state.modell.price = service.price;
    state.modell.name = service.name;
  },
  SET_INVENTORY: (state, inventory) => {
    state.inventory = inventory;
  },
  UPDATE_COLUMN: (state, column) => {
    state.columns[column.key].show = column.value;
  },
  UPDATE_SORT: (state, sort) => {
    state.sort = sort;
  },
  UPDATE_PAGINATION: (state, pagination) => {
    state.pagination[pagination.key] = pagination.value;
  },
  EMPTY_MODEL: (state) => {
    state.list = [];
    state.total_amount = '';
  },
  REFRESH: (state) => {
    state.filter = JSON.parse(JSON.stringify(filter));
    // state.sort = JSON.parse(JSON.stringify(sort));
    state.pagination = JSON.parse(JSON.stringify(pagination));
  },
  UPDATE_LANG_COLUMN: (state) => {
        state.columns.id.title = "№";
        state.columns.user.title = i18n.t('message.users');
        state.columns.wlec.title =  i18n.t('message.show_drug');
        state.columns.settings.title = i18n.t('message.settings');
        state.columns.wdata.title = i18n.t('message.date_movement');
        state.columns.Warehouses1.title = i18n.t('message.main_warehouses');
        state.columns.warehouses2.title = i18n.t('message.warehouse_movement');
  }
}