import { actions } from './warehouse/actions'
import { getters } from './warehouse/getters'
import { state } from './warehouse/state'
import { mutations } from './warehouse/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}
