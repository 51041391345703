export const filter = {
    id: "",
    name: "",
    surname: "",
    patronymic: "",
    note: "",
    id_code: "",
    search: "",
    created_at: "",
    updated_at: "",
    start_date: '',
    end_date: '',
};
