<template>
  <div class="top-collectors chart-card">
    <div class="top-collectors__header chart-card__header">
      <h2>{{ title }}</h2>
      <el-dropdown>
        <span class="el-dropdown-link">
          {{ $t(`message.${selected_item}`)
          }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="item in dateItems" @click.native="selected_item = item" :key="item">{{
              $t(`message.${item}`) }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <div class="center">
      <el-date-picker v-model="value1" type="daterange" :start-placeholder="$t('message.start_period')"
                      :end-placeholder="$t('message.end_period')" v-if="selected_item === 'period'">
      </el-date-picker>
    </div>

    <div class="clinics-report">
      <el-row class="p-4 d-flex">
        <el-col class="pr-2">
          <h6>{{ $t(`message.patients_count`) }}</h6>
          <span>{{ reports[selected_item].patients_count | formatMoney }}</span>
        </el-col>
        <el-col class="pl-2">
          <h6>{{ $t(`message.patients_income`) }}</h6>
          <span>{{ reports[selected_item].patients_income | formatMoney }} cум</span>
        </el-col>
      </el-row>
      <el-row class="p-4 d-flex">
        <el-col class="pr-2">
          <h6>{{ $t(`message.hospital`) }}</h6>
          <span>{{ reports[selected_item].hospital_count | formatMoney }}</span>
        </el-col>
        <el-col class="pl-2">
          <h6>{{ $t(`message.hospital_income`) }}</h6>
          <span>{{ reports[selected_item].hospital_income | formatMoney }} сум</span>
        </el-col>
      </el-row>
      <el-row class="p-4 d-flex">
        <el-col class="pr-2">
          <h6>{{ $t(`message.daily_hospital`) }}</h6>
          <span>{{ reports[selected_item].day_hospital_count | formatMoney }}</span>
        </el-col>
        <el-col class="pl-2">
          <h6>{{ $t(`message.day_hospital_income`) }}</h6>
          <span>{{ reports[selected_item].day_hospital_income | formatMoney }} сум</span>
        </el-col>
      </el-row>
      <el-row class="p-4 d-flex">
        <el-col class="pr-2">
          <h6>{{ $t(`message.consultation`) }}</h6>
          <span>{{ reports[selected_item].consultation_services_count | formatMoney }}</span>
        </el-col>
        <el-col class="pl-2">
          <h6>{{ $t(`message.consultations_income`) }}</h6>
          <span>{{ reports[selected_item].consultation_services_income | formatMoney }} сум</span>
        </el-col>
      </el-row>
      <el-row class="p-4 d-flex">
        <el-col class="pr-2">
          <h6>{{ $t(`message.another_services`) }}</h6>
          <span>{{ reports[selected_item].another_services_count | formatMoney }}</span>
        </el-col>
        <el-col class="pl-2">
          <h6>{{ $t(`message.another_services_income`) }}</h6>
          <span>{{ reports[selected_item].another_services_income | formatMoney }} сум</span>
        </el-col>
      </el-row>
      <el-row class="p-4 d-flex">
        <el-col class="pr-2">
          <h6>{{ $t(`message.totaly`) }}</h6>
        </el-col>
        <el-col class="pl-2">
          <span>{{ reports[selected_item].patients_income | formatMoney }} сум</span>
        </el-col>
      </el-row>
    </div>

  </div>
</template>

<script>
import { formatMoney } from "@/filters"
import axios from "axios";
import {mapGetters} from "vuex";

export default {
  props: ["title", "reports"],
  computed: {
    ...mapGetters({
      token: 'auth/token',
    }),
    totalIncome() {
      const selectedReport = this.reports[this.selected_item]

      // Суммируем все инкомы
      // Возвращаем сумму
      return (
        selectedReport.patients_income +
        selectedReport.hospital_income +
        selectedReport.day_hospital_income +
        selectedReport.consultation_services_income +
        selectedReport.another_services_income
      )
    }
  },
  data() {
    return {
      selected_item: "daily",
      value1: '',
      backend_url: process.env.VUE_APP_URL,
      custom_period_value: '',
      dateItems: [
        "daily",
        "weekly",
        "monthly",
        "quarterly",
        "half_yearly",
        "yearly",
        "period"
      ],
    }
  },
  methods: {
    formatMoney,
    userName(item) {
      if (item.user) return item.user.name + " " + item.user.surname
      if (item.partner_doctor) return item.partner_doctor.name
      return ""
    },
    percentage(item) {
      return parseFloat(
        ((item.bonus_amount * 100) / this.doctor.sum_doctor_bonus).toFixed(2)
      )
    },
    async fetchData() {
      try {
        const response = await axios.get(this.backend_url + 'dashboard/clinic/custom-period', {
          params: {
            start_date: this.value1[0],
            end_date: this.value1[1],
          },
          headers: {
            'Authorization': 'Bearer ' + this.token,
          },
        });
        return response.data;
      } catch (error) {
        console.error('Ошибка при выполнении запроса:', error);
      }
    },
  },
  watch: {
    value1: function (val) {
      if (val) {
        this.selected_item = 'period';
        this.custom_period_value = val;
        this.fetchData()
          .then((customValues) => {
            console.log(customValues.data);
            this.$set(this.reports, 'period', customValues.data);
          });
      }
    },
    selected_item: function (val) {
      if (this.selected_item === 'period') {
        this.value1 = this.custom_period_value;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.chart-card {
  height: 100%;
}

.clinics-report {
  position: relative;

  // &::after {
  //   position: absolute;
  //   content: '';
  //   width: 1px;
  //   height: 100%;
  //   background: #00000018;
  //   left: 47%;
  //   top: 0;
  //   transform: translateX(-50%);
  // }
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.clinics-report {
  h6 {
    margin: 0;
    color: #222;
  }

  span {
    color: #10b981;
    font-size: 14px;
  }

  .el-row {
    border-bottom: 1px solid #eee;
  }
}
</style>
