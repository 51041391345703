import request from './../utils/request'


export function index(params) {
  	return request({
  	  url: '/doctors-report',
  	  method: 'get',
        params
  	})
}

export function getRep(params) {
  return request({
    url: '/doctors-report/doctorsRep',
    method: 'get',
    params,
    responseType: 'blob'
  })
}

export function show(params) {
  return request({
      url: `/doctors-report/${params.id}`,
      method: 'get',
      params
    })
}
