export const rules = {

  warehouse_id: [
    { required: true, message: 'Пожалуйста укажите склад', trigger: 'blur' },
  ],
  mainwarehouse_id: [
    { required: true, message: 'Пожалуйста укажите склад', trigger: 'blur' },
  ],
  product_id: [
    { required: true, message: 'Пожалуйста укажите Продукт', trigger: 'blur' },
  ],

};