export const filter = {
    id: "",
    payment_type_id: "",
    price: "",
    // currency_id: "",
    rate: "",
    date: "",
    comment: "",
    transactionable_id: "",
    transactionable_type: "",
    user_id: "",
    debit: "",
    created_at: "",
    updated_at: "",
};
