import Layout from '@/layouts'

const paymentType = {
  path: '/normaLaboratory',
  component: Layout,
  name: 'normaLaboratory',
  redirect: '/normaLaboratory/index',
  children: [{
    path: '/normaLaboratory/index',
    component: () => import('@/views/normaLaboratory/index'),
    name: 'normaLaboratory.index',
    meta: {
      title: 'Норма  Лаборатория'
    }
  }, ]
}

export default paymentType