import request from "../utils/request";

export function index(params) {
  return request({
    url: "/materials",
    method: "get",
    params,
  });
}

export function show(id) {
  return request({
    url: `/materials/${id}`,
    method: "get",
  });
}

export function store(data) {
  return request({
    url: "/materials",
    method: "post",
    data,
  });
}

export function update(data) {
  return request({
    url: `/materials/${data.id}`,
    method: "put",
    data,
  });
}

export function destroy(id) {
  return request({
    url: `/materials/${id}`,
    method: "delete",
  });
}