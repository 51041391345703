export const rules = {
    // name: [
    //     { required: true, message: 'Пожалуйста, введите Койка ', trigger: 'blur' },
    // ],
    // corps_id: [
    //     { required: true, message: 'Пожалуйста, введите Корпус ', trigger: 'blur' },
    // ],
    // floor_id: [
    //     { required: true, message: 'Пожалуйста, введите Этаж ', trigger: 'blur' },
    // ],
    // class_id: [
    //     { required: true, message: 'Пожалуйста, введите Класс ', trigger: 'blur' },
    // ],
    // hospitalRoom_id: [
    //     { required: true, message: 'Пожалуйста, введите Комната ', trigger: 'blur' },
    // ],
};
