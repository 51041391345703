export const model = {
    id: null,
    company_name: '',
    phone: '',
    code: '',
    fax: '',
    email: '',
    address: '',
    inn: '',
    mfo: '',
    okonx: '',
    oked: '',
    rkp_nds: '',
    created_at: '',
    updated_at: ''
};
