export const model = {
    id: null,
    payment_type_id: "",
    price: "",
    // currency_id: "",
    rate: "",
    date: "",
    comment: "",
    transactionable_id: "",
    transactionable_type: "",
    user_id: "",
    debit: "",
    created_at: '',
    updated_at: ''
};
