import { nurseCabinet, updateFromNurse } from '../../../api/orders';

export const actions = {

    nurseCabinet({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            nurseCabinet(params).then(res => {
                commit("SET_LIST", res.data.data.orders);
                commit("UPDATE_PAGINATION", { key: 'total', value: res.data.data.pagination.total });
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    updateFromNurse({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            updateFromNurse(data).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    updateSort({ commit }, sort) {
        commit("SET_SORT", sort)
    },

    updateFilter({ commit }, filter) {
        commit('SET_FILTER', JSON.parse(JSON.stringify(filter)))
    },

    updateColumn({ commit }, obj) {
        commit('UPDATE_COLUMN', obj)
    },

    updatePagination({ commit }, pagination) {
        commit('UPDATE_PAGINATION', pagination)
    },

    refreshData({ commit }) {
        return new Promise((resolve, reject) => {
            commit("REFRESH");
            resolve()
        })
    },
    empty({ commit }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_MODEL");
            resolve()
        })
    },
    chart({ commit }, type) {
        return new Promise((resolve, reject) => {
            chart({ type: type }).then(res => {
                resolve(res.data.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

}