<template>
  <el-select
    :value="selected"
    @input="dispatch"
    :placeholder="plc || $t('message.region') "
    filterable
    clearable
    :size="size"
    class="d-block"
  >
    <el-option
      v-for="clinic in clinics"
      :key="clinic.id"
      :label="clinic.name"
      :value="clinic.id"
    ></el-option>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import inventory from "@/utils/mixins/components/inventory";

export default {
  mixins: [inventory],
  props: ["partenr_clinic_id"],
  watch: {
    partenr_clinic_id: {
      handler: function () {
        this.dispatch(this.partenr_clinic_id);
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    if (this.clinics && this.clinics.length === 0) {
      this.getInventory();
    }
  },
  computed: {
    ...mapGetters({
      clinics: "partnerClinic/inventory_all",
    }),
  },
  methods: {
    ...mapActions({
      getInventory: "partnerClinic/inventoryAll",
    }),
   
  },
};
</script>