import { i18n } from '@/utils/i18n';

export const columns = {
    from: {
        show: true,
        title: i18n.t('message.from_time'),
        sortable: true,
        column: 'from'
    },
    to: {
        show: true,
        title: i18n.t('message.to_time'),
        sortable: true,
        column: 'to'
    },
    room_id: {
        show: true,
        title: i18n.t('message.room'),
        sortable: true,
        column: 'room_id'
    },
};
