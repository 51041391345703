import request from './../utils/request'


export function index(params) {
    return request({
      url: '/socials',
      method: 'get',
        params
    })
  }

export function show(id) {
    return request({
      url: `/socials/${id}`,
      method: 'get'
    })
  }

  export function store(data) {
    return request({
      url: '/socials',
      method: 'post',
      data
    })
  }

  export function update(data) {
    return request({
      url: `/socials/${data.id}`,
      method: 'put',
      data
    })
  }

  export function destroy(id) {
    return request({
      url: `/socials/${id}`,
      method: 'delete',
    })
  }
