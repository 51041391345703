import { sort } from './../properties/sort';
import { pagination } from './../properties/pagination';
import { filter } from './properties/filter';
import { model } from './properties/model';
import { i18n } from '@/utils/i18n';

export const mutations = {
  SET_LIST: (state, suppliers) => {
    state.list = suppliers;
  },
  SET_FILTER: (state, filter) => {
    state.filter = filter;
  },
  SET_SORT: (state, sort) => {
    state.sort = sort;
  },
  SET_MODEL: (state, model) => {
    state.model = model;
  },
  SET_INVENTORY: (state, inventory) => {
    state.inventory = inventory;
  },
  UPDATE_COLUMN: (state, column) => {
    state.columns[column.key].show = column.value;
  },
  UPDATE_SORT: (state, sort) => {
    state.sort = sort;
  },
  UPDATE_PAGINATION: (state, pagination) => {
    state.pagination = pagination;
  },
  EMPTY_MODEL: (state) => {
    state.model = JSON.parse(JSON.stringify(model))
  },
  REFRESH: (state) => {
    state.filter = JSON.parse(JSON.stringify(filter));
    // state.sort = JSON.parse(JSON.stringify(sort));
    state.pagination = JSON.parse(JSON.stringify(pagination));
  },
  UPDATE_LANG_COLUMN: (state) => {
      state.columns.id.title = '№';
      state.columns.number.title = i18n.t('message.number_aggrement');
      state.columns.begin_date.title = i18n.t('message.date_aggrement');
      state.columns.supplier_id.title = i18n.t('message.suppliers');
      state.columns.status_id.title = i18n.t('message.status');
      state.columns.sum.title = i18n.t('message.sum');
      state.columns.description.title = i18n.t('message.description');
      state.columns.created_at.title = i18n.t('message.date');
      state.columns.updated_at.title = i18n.t('message.update_date');
      state.columns.settings.title = i18n.t('message.settings');
  }
}