import { columns } from './properties/columns'
import { columns2 } from './properties/columns2'
import { expired_columns } from './properties/expired_columns'
import { filter } from './properties/filter'
import { sort } from './../properties/sort'
import { pagination } from './../properties/pagination'
import { model } from "./properties/model";
import { rules } from "./properties/rules";

export const state = {
    list: [],
    patient_list: [],
    expired_list: [],
    term_list: [],
    patient_medicine: [],
    inventory: [],
    prices: [],
    model: JSON.parse(JSON.stringify(model)),
    relation_model: [],
    columns: columns,
    columns2: columns2,
    expired_columns: expired_columns,
    filter: filter,
    pagination: JSON.parse(JSON.stringify(pagination)),
    expired_pagination: JSON.parse(JSON.stringify(pagination)),
    sort: JSON.parse(JSON.stringify(sort)),
    expired_sort: JSON.parse(JSON.stringify(sort)),
    rules: rules
};