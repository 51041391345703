import { actions } from './department/actions'
import { getters } from './department/getters'
import { state } from './department/state'
import { mutations } from './department/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}
