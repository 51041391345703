import { index } from '../../../api/priceLists';

export const actions = {

    index({commit}, params = {}) {
        return new Promise((resolve, reject) => {
            index(params).then(res => {
                commit("SET_LIST", res.data.data.category_services.data);
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

}
