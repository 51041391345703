import Layout from '@/layouts'

const specialty = {
  path: '/nationality',
  component: Layout,
  name: 'nationality',
  redirect: '/nationality/index',
  children: [{
    path: '/nationality/index',
    component: () => import('@/views/nationality/index'),
    name: 'nationality.index',
    meta: {
      title: 'Национальность'
    }
  }, ]
}

export default specialty