import Layout from '@/layouts'

const paymentType = {
  path: '/conResources',
  component: Layout,
  name: 'conResources',
  redirect: '/conResources/index',
  children: [{
    path: '/conResources/index',
    component: () => import('@/views/conResources/index'),
    name: 'conResources.index',
    meta: {
      title: 'Подключение ресурсов'
    }
  }, ]
}

export default paymentType