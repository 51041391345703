export const getters = {
    list: state => state.list,
    expiredList: state => state.expired_list,
    inventory: (state) => state.inventory,
    termList: state => state.term_list,
    columns: state => state.columns,
    columns2: state => state.columns2,
    expired_columns: state => state.expired_columns,
    model: state => state.model,
    relation_model: state => state.relation_model,
    filter: state => state.filter,
    pagination: state => state.pagination,
    expired_pagination: state => state.expired_pagination,
    sort: state => state.sort,
    expired_sort: state => state.expired_sort,
    rules: state => state.rules,
    prices: state => state.prices,
    patient_list: state => state.patient_list,
    patient_medicine: state => state.patient_medicine,
};
