import { index, show, store, update, destroy, print, printCenter, patientConculation, indexitems } from '../../../api/hospitalizationConclusion';

export const actions = {

    index({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            index(params).then(res => {
                commit("SET_LIST", res.data.data.data);
                commit("UPDATE_PAGINATION", { key: 'total', value: res.data.data.pagination.total });
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },
    indexitems({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            indexitems(params)
                .then(response => {
                    commit("SET_LIST", response.data.data.data);
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },

    show({ commit }, id) {
        return new Promise((resolve, reject) => {
            show(id).then(res => {
                commit("SET_MODEL", res.data.data.patient_conculation);
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    print({ commit }, data) {
        return new Promise((resolve, reject) => {
            print(data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    printCenter({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            printCenter(params).then(res => {
                commit("UPDATE_PAGINATION", { key: 'total', value: res.data.data.pagination.total });
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    patientConculation({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            patientConculation(params).then(res => {
                commit("UPDATE_PAGINATION", { key: 'total', value: res.data.data.pagination.total });
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    store({ commit }, data) {
        return new Promise((resolve, reject) => {
            store(data).then(res => {
                commit("CREATE_MODEL", res.data.data.patient_conculation);
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    update({ commit }, data) {
        return new Promise((resolve, reject) => {
            update(data).then(res => {
                commit("UPDATE_MODEL", res.data.data.patient_conculation);
                resolve(res.data)
            }).catch(err => {
                reject(err.response)
            })
        })
    },

    destroy({ commit }, id) {
        return new Promise((resolve, reject) => {
            destroy(id).then(res => {
                commit('DELETE_MODEL', id);
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    updateSort({ commit }, sort) {
        commit("SET_SORT", sort)
    },

    updateFilter({ commit }, filter) {
        commit('SET_FILTER', JSON.parse(JSON.stringify(filter)))
    },

    updateColumn({ commit }, obj) {
        commit('UPDATE_COLUMN', obj)
    },

    updatePagination({ commit }, pagination) {
        commit('UPDATE_PAGINATION', pagination)
    },

    refreshData({ commit }) {
        return new Promise((resolve, reject) => {
            commit("REFRESH");
            resolve()
        })
    },
    empty({ commit }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_MODEL");
            resolve()
        })
    },


}
