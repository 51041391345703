export const getters = {
    list: state => state.list,
    fileList: state => state.fileList,
    model: state => state.model,
    rules: state => state.rules,
    columns: state => state.columns,
    filter: state => state.filter,
    pagination: state => state.pagination,
    sort: state => state.sort,
    form: state => {
        return {
            id: state.model.id,
            address: state.model.address,
            web: state.model.web,
            email: state.model.email,
            number: state.model.number,
        }
    }
};