export default {
  props: {
    size: {
      default: "small",
    },
    plc: {
      default: null,
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  methods: {
    dispatch(e) {
      this.$emit("input", e);
      this.selected = e;
    },
  },
};
