import request from '../utils/request'


export function index(params) {
  	return request({
  	  url: '/dailyTreatments',
  	  method: 'get',
      params
  	})
  }
  // export function allData(params) {
  // 	return request({
  // 	  url: '/dailyTreatments',
  // 	  method: 'get',
  //     params
  // 	})
  // }

  export function allDefect(params) {
  	return request({
  	  url: '/dailyTreatments/allDefect',
  	  method: 'get',
        params
  	})
  }

  export function inventory(params) {
    return request({
      url: '/dailyTreatments/inventory',
      method: 'get',
      params
    })
  }


  export function show(params) {
    return request({
      url: `/dailyTreatments/${params.hospital_patient_id}`,
      method: 'get',
      params
    })
  }
  export function getLastNumber(params) {
    return request({
      url: `/dailyTreatments/getLastNumber/${params.id}`,
      method: 'get',
      params
    })
  }
  export function getSelectedNumber(params) {
    return request({
      url: `/dailyTreatments/getSelectedNumber`,
      method: 'get',
      params
    })
  }


  export function defect(params) {
    return request({
      url: `/dailyTreatments/defect`,
      method: 'get',
      params
    })
  }


  export function showItem(id) {
    return request({
      url: `/dailyTreatments/showItem/${id}`,
      method: 'get'
    })
  }


  export function store(data) {
    return request({
      url: '/dailyTreatments',
      method: 'post',
      data
    })
  }

  export function update(data) {
    return request({
      url: `/dailyTreatments/${data.id}`,
      method: 'put',
      data
    })
  }

  export function destroy(id) {
    return request({
      url: `/dailyTreatments/${id}`,
      method: 'delete',
    })
  }
