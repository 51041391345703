<template>
  <el-select
    :value="selected"
    @input="dispatch"
    :placeholder="plc || 'Корпус'"
    filterable
    clearable
  >
    <el-option v-for="item in corpses" :key="item.id" :label="item.name" :value="item.id"></el-option>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import inventory from '@/utils/mixins/components/inventory';

export default {
  props: ['corps_id'],
  mixins: [inventory],
  mounted() {
    if (this.corpses && this.corpses.length === 0) {
      this.getInventory();
    }
  },
  watch: {
    corps_id:{
       handler: function (val, oldVal) { 
          this.selected = val;
          this.$emit("input", this.selected);
       },
       deep: true,
       immediate: true
    },
  },
  computed: {
    ...mapGetters({
      corpses: 'corpses/inventory',
    }),
  },
  methods: {
    ...mapActions({
      getInventory: 'corpses/inventory',
    })
  },
};
</script>
