import { actions } from './status/actions'
import { getters } from './status/getters'
import { state } from './status/state'
import { mutations } from './status/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}
