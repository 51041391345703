export const rules = {
  name: [
    { required: true, message: 'Пожалуйста укажите наименование', trigger: 'blur' },
  ],
  price: [
    { required: true, message: 'Пожалуйста укажите стоимость', trigger: 'blur' },
  ],
  quantity: [
    { required: true, message: 'Пожалуйста укажите количество', trigger: 'blur' },
  ],
  category_id: [
    { required: true, message: 'Пожалуйста укажите группу', trigger: 'blur' },
  ],
  currency_id: [
    { required: true, message: 'Пожалуйста укажите валюта', trigger: 'blur' },
  ],
  measurement_id: [
    { required: true, message: 'Пожалуйста укажите единицу измерения', trigger: 'blur' },
  ],
};