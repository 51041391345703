import request from './../utils/request'


export function index(params) {
  	return request({
  	  url: '/nationalities',
  	  method: 'get',
        params
  	})
  }
 
  export function inventory(params) {
    return request({
      url: '/nationalities/inventory',
      method: 'get',
      params
    })
  }

export function show(id) {
    return request({
      url: `/nationalities/${id}`,
      method: 'get'
    })
  }

  export function store(data) {
    return request({
      url: '/nationalities',
      method: 'post',
      data
    })
  }

  export function update(data) {
    return request({
      url: `/nationalities/${data.id}`,
      method: 'put',
      data
    })
  }

  export function destroy(id) {
    return request({
      url: `/nationalities/${id}`,
      method: 'delete',
    })
  }
