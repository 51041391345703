import {
    index,
    show,
    inventory,
    store,
    update,
    destroy,
} from "@/api/sickNumber";

export const actions = {
    index({
        commit
    }, params = {}) {
        return new Promise((resolve, reject) => {
            index(params)
                .then((res) => {
                    commit("SET_LIST", res.data.data.sick_numbers.data);
                    commit("UPDATE_PAGINATION", {
                        key: "total",
                        value: res.data.data.sick_numbers.pagination.total,
                    });
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    inventory({
        commit
    }, params = {}) {
        return new Promise((resolve, reject) => {
            inventory(params)
                .then((res) => {
                    commit("SET_INVENTORY", res.data.data.sick_numbers);
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
   
    show({
        commit
    }, id) {
        return new Promise((resolve, reject) => {
            show(id)
                .then((res) => {
                    commit("SET_MODEL", res.data.data.sick_number);
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err.response.data);
                });
        });
    },

    store({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            store(data)
                .then((res) => {
                    commit("SET_MODEL", res.data.data.sick_numbers);
                    resolve(res.data);
                })
                .catch((err) => {
                    typeof err.response != "undefined" ?
                        reject(err.response.data) :
                        reject(err);
                });
        });
    },

    update({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            update(data)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err.response.data);
                });
        });
    },

    destroy({
        commit
    }, id) {
        return new Promise((resolve, reject) => {
            destroy(id)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err.response.data);
                });
        });
    },

    updateSort({
        commit
    }, sort) {
        commit("SET_SORT", sort);
    },

    updateFilter({
        commit
    }, filter) {
        commit("SET_FILTER", JSON.parse(JSON.stringify(filter)));
    },

    updateColumn({
        commit
    }, obj) {
        commit("UPDATE_COLUMN", obj);
    },

    updatePagination({
        commit
    }, pagination) {
        commit("UPDATE_PAGINATION", pagination);
    },
    empty({
        commit
    }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_MODEL");
            resolve()
        })
    },
    refreshData({
        commit
    }) {
        return new Promise((resolve, reject) => {
            commit("REFRESH");
            resolve();
        });
    },
};