<template>
  <div class="chart-card">
    <div class="chart-card__header">
      <h2>{{ title }}</h2>
      <div v-if="filter" class="bd-highlight">
        <el-radio-group v-model="radio" size="mini">
          <el-radio-button value="daily" label="daily">{{ $t('message.daily') }}</el-radio-button>
          <el-radio-button value="week" label="weekly">{{ $t('message.week') }}</el-radio-button>
          <el-radio-button value="monthly" label="monthly">{{ $t('message.month') }}</el-radio-button>
        </el-radio-group>
      </div>
      <!-- <el-dropdown>
        <span class="el-dropdown-link">
          View All<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>Action 1</el-dropdown-item>
          <el-dropdown-item>Action 2</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown> -->
    </div>

    <div>
      <apexchart ref="chart" class="chart" type="donut" width="250" :options="chartOptions" :series="series"></apexchart>
    </div>

    <div class="candidates-wrap">
      <div class="candidates-wrap__item" v-for="(item, index) in candidatesInfo" :key="index">

        <span>{{ item.title }}</span>
        <p>
          {{ item.total | formatMoney }}
          <!-- <span :style="{ color: item.pratcent > 0 ? '#22c55e' : '#ef4444' }">
            <i
              :class="[
                item.pratcent > 0 ? 'fa-caret-up' : 'fa-caret-down',
                'fa-solid',
              ]"
            ></i
            >{{ Math.abs(item.pratcent) }}% 
          </span> -->
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { i18n } from "@/utils/i18n";
export default {
  props: ['data', 'title', 'firstChild', 'secondChild', 'filter', 'getColors'],
  data() {
    return {
      show: false,
      series: [1624, 1256],
      radio: 'daily',
      chartOptions: {
        theme: {
          mode: 'light'
        },
        chart: {
          background: 'transparent',
          width: 380,
          type: "donut",
        },
        plotOptions: {
          pie: {
            startAngle: 0,
            // endAngle: 270
          },
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          type: "gradient",
        },
        legend: {
          show: false,
          formatter: function (val, opts) {
            return val + " - " + opts.w.globals.series[opts.seriesIndex];
          },
        },
        labels: [],
        colors: ["#8b5cf6", "#0ea5e9", "#f59e0b", "#10b981"],
      },
      candidatesInfo: [
        {
          title: this.firstChild,
          total: 1233,
        },
        {
          title: this.secondChild,
          total: 1233,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      mode: 'theme/MODE'
    })
  },
  watch: {
    data: function (val) {
      this.setData(val);
    },
    mode(bool) {
      this.updateChart(bool)
    }
  },
  created() {
    if (this.getColors) this.chartOptions.colors = this.getColors
    console.log(this.chartOptions);
  },
  mounted() {
    this.updateChart(this.mode)

  },
  methods: {
    setData(val) {
      this.show = false;
      this.series = [val.man, val.woman];
      this.chartOptions.labels = [];
      this.chartOptions.labels.push(this.firstChild);
      this.chartOptions.labels.push(this.secondChild);

      this.candidatesInfo = [
        {
          title: this.firstChild,
          total: val.man,
        },
        {
          title: this.secondChild,
          total: val.woman,
        },
      ];
      this.show = true;
    },
    updateChart(bool) {
      this.$refs.chart.updateOptions({
        theme: {
          mode: bool ? 'dark' : 'light'
        }
      })
    }
  },
};
</script>

<style lang="scss">
.chart-card .chart>div {
  margin: 0 auto !important;
}
</style>

<style lang="scss" scoped>
.candidates-wrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border-top: 1px solid #e5e7eb;

  // candidates-wrap__item
  &__item {
    padding: 1rem;
    text-align: center;

    &:first-child {
      border-right: 1px solid #e5e7eb;
    }

    span {
      color: #9ca3af;
      font-size: 14px;
    }

    p {
      font-weight: 500;
      font-size: 24px;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;

      span i {
        display: inline-block;
        margin-right: 4px;
      }
    }
  }
}
</style>