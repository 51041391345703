import request from './../utils/request'


export function index(params) {
    return request({
        url: '/patient/age',
        method: 'get',
        params
    })
}



