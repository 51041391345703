export const getters = {
    list: state => state.list,
    search_list: state => state.search_list,
    inventory: state => state.inventory,
    columns: state => state.columns,
    model: state => state.model,
    filter: state => state.filter,
    pagination: state => state.pagination,
    sort: state => state.sort,
    rules: state => state.rules,
    medcine: state => state.medcine,
    total_amount: state => state.total_amount,
    therapy: state => state.therapy,
    pocket_balance: state => state.pocket_balance,
    pocket: state => state.pocket,
    therapy_amount: state => state.therapy_amount,
    classs: state => state.classs,
    classs_amount: state => state.classs_amount,
    modal_balance: state => state.modal_balance,
    balance_report: state => state.balance_report,
};
