import request from './../utils/request'


export function index(params) {
  	return request({
  	  url: '/contracts',
  	  method: 'get',
        params
  	})
  }

export function show(params) {
    return request({
      url: `/contracts/${params.id}`,
      method: 'get',
      params
    })
  }

  export function contractServices(params) {
    return request({
      url: `/contracts/${params.id}`,
      method: 'get',
      params
    })
  }

  export function store(data) {
    return request({
      url: '/contracts',
      method: 'post',
      data
    })
  }

  export function addContractServices(data) {
    return request({
      url: '/add-contract-services',
      method: 'post',
      data
    })
  }

  export function update(data) {
    return request({
      url: `/contracts/${data.id}`,
      method: 'put',
      data
    })
  }

  export function destroy(id) {
    return request({
      url: `/contracts/${id}`,
      method: 'delete',
    })
  }
