export const filter = {
    id: "",
    name: "",
    is_report: "",
    is_conclusion: "",
    is_register: "",
    search: "",
    created_at: "",
    updated_at: "",
};
