<template>
  <div
    class="chart-card"
    style="
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    "
  >
    <div class="chart-card__header">
      <h2 class="mi">{{ $t("message.patient_ages") }}</h2>
      <!-- <el-dropdown>
        <span class="el-dropdown-link">
          <i class="fa-solid fa-ellipsis-vertical"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>Action 1</el-dropdown-item>
          <el-dropdown-item>Action 2</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown> -->
    </div>
    <apexchart
      v-if="show"
      type="donut"
      width="250"
      style="margin: 0 auto"
      :options="chartOptions"
      :series="series"
    ></apexchart>
    <div class="statistic">
      <div
        v-for="(item, index) in statistic"
        :key="index"
        class="statistic__item"
      >
        <span>{{ item.name }}</span>
        <p class="count">{{ formatNumber(item.count) }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import formatNumber from "@/utils/formatNumber.js";
import { i18n } from "@/utils/i18n";
export default {
  props: ["age"],
  data() {
    return {
      show: false,
      series: [1624, 1256, 1153, 679],
      chartOptions: {
        chart: {
          width: 450,
          type: "donut",
        },
        labels: ["Mobile", "Desktop", "Laptop", "Tablet"],
        plotOptions: {
          pie: {
            startAngle: 0,
            // endAngle: 270
          },
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          type: "gradient",
        },
        legend: {
          show: false,
          formatter: function (val, opts) {
            return val + " - " + opts.w.globals.series[opts.seriesIndex];
          },
        },
        colors: ["#8b5cf6", "#0ea5e9", "#f59e0b", "#7feb71","#10b981"],
      },

      statistic: [
        {
          name: "Mobile",
          count: 1624,
        },
        {
          name: "Desktop",
          count: 1256,
        },
        {
          name: "Laptop",
          count: 1153,
        },
        {
          name: "Tablet",
          count: 679,
        },
      ],
    };
  },
  watch: {
    age: function (newVal, oldVal) {
      this.setData(newVal);
    },
  },
  methods: {
    setData(val) {
      this.show = false;

      this.series = [];
      this.statistic = [];
      this.chartOptions.labels = [];
      for (const key in val) {
        const element = val[key];

        if (key == "not_set")
          this.chartOptions.labels.push(i18n.t("message.not_set"));
        else this.chartOptions.labels.push(key);

        this.series.push(parseFloat(element));

        if (key == "not_set")
          this.statistic.push({
            name: i18n.t("message.not_set"),
            count: element,
          });
        else
          this.statistic.push({
            name: key,
            count: element,
          });
      }
      this.show = true;
    },
    formatNumber,
  },
};
</script>

<style lang="scss" scoped>
.statistic {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.2rem;
  padding: 0 20px;
  border-top: 1px solid #e5e7eb;
  margin-top: 1rem;

  // statistic__item
  &__item {
    text-align: center;

    span {
      position: relative;
      font-size: 14px;
      font-weight: 500;
      color: #9ca3af;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: -12px;
        transform: translateY(-50%);
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #8b5cf6;
      }
    }

    .count {
      font-weight: 700;
      font-size: 16px;
      color: #444;
    }

    &:nth-child(2) span::before {
      background: #0ea5e9;
    }

    &:nth-child(3) span::before {
      background: #f59e0b;
    }

    &:nth-child(4) span::before {
      background: #10b981;
    }
  }
}
</style>