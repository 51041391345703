<template>
  <el-select
    :value="selected"
    @input="dispatch"
    :placeholder="plc || $t('message.measurement_full')"
    filterable
    clearable
    :size="size"
    class="d-block"
  >
    <el-option
      v-for="measurement in measurements"
      :key="measurement.id"
      :label="measurement.full_name"
      :value="measurement.id"
    ></el-option>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import inventory from "@/utils/mixins/components/inventory";

export default {
  mixins: [inventory],
  mounted() {
    if (this.measurements && this.measurements.length === 0) {
      this.getInventory();
    }
  },
  computed: {
    ...mapGetters({
      measurements: "measurements/inventory",
    }),
  },
  methods: {
    ...mapActions({
      getInventory: "measurements/inventory",
    }),
  },
};
</script>