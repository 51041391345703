import { actions } from './owner_branch_headers/actions'
import { getters } from './owner_branch_headers/getters'
import { state } from './owner_branch_headers/state'
import { mutations } from './owner_branch_headers/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}