import { render, staticRenderFns } from "./TopPartnerCollectors.vue?vue&type=template&id=10a59b87&scoped=true&"
import script from "./TopPartnerCollectors.vue?vue&type=script&lang=js&"
export * from "./TopPartnerCollectors.vue?vue&type=script&lang=js&"
import style0 from "./TopPartnerCollectors.vue?vue&type=style&index=0&id=10a59b87&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10a59b87",
  null
  
)

export default component.exports