import { index, store, destroy,inventory} from '../../../api/workTimes';

export const actions = {

    index({commit}, params = {}) {
        return new Promise((resolve, reject) => {
            index(params).then(res => {
                commit("SET_LIST", res.data.data.work_times.data);
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },
    
    store({commit},data) {
        return new Promise((resolve,reject) => {
            store(data).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },
    
    destroy({commit},id) {
        return new Promise((resolve,reject) => {
            destroy(id).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },
    
    inventory({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            inventory(params)
            .then(res => {
              commit('SET_INVENTORY', res.data.data.work_times.data);
              resolve(res.data);
            })
            .catch(error => {
              reject(error.res.data);
            });
        });
      }, 

    updateFilter({commit}, filter){
        commit('SET_FILTER', JSON.parse( JSON.stringify( filter)))
    },

    refreshData({commit}){
        return new Promise((resolve, reject) => {
            commit("REFRESH");
            resolve()
        })
    },
    empty({ commit }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_MODEL");
            resolve()
        })
    },


}
