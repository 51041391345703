import {sort} from "../properties/sort";
import {filter} from "./properties/filter";
import {pagination} from "../properties/pagination";
import {model} from "./properties/model";
import { i18n } from '@/utils/i18n';

export const mutations = {
    SET_LIST: (state,limit) => (state.list = limit),
    SET_LIMIT: (state,limit) => (state.limit = limit),
    SET_SORT: (state,sort) => (state.sort = sort),
    SET_FILTER: (state,filter) => (state.filter = filter),
    SET_PAGINATION: (state,pagination) => (state.pagination = pagination),
    UPDATE_PAGINATION: (state, pagination) => {
        state.pagination[pagination.key] = pagination.value
    },
    UPDATE_COLUMN: (state, obj) => {
        state.columns[obj.key].show = obj.value
    },
    UPDATE_SORT: (state, sort) => {
        state.sort[sort.column] = sort.order
    },
  

};
