export const model = {
    id:  null,
    code: "",
    phone:  "",
    gender: "",
    street: "",
    surname: "",
    district: "",
    born_date: "",
    given_date: "",
    patronymic: "",
    first_name: "",
    born_place: "",
    city_id: "",
    region_id: "",
    state_id:  "",
    citizenship_id: "",
    nationality_id: "",
    partner_doctor_id: "",
    organization_id: "",
    created_at: "",
    updated_at: "",
    passport_number: "",
    patient_schedule_id: "",
};
