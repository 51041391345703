export const rules = {
    first_name: [
        { required: true, message: 'Пожалуйста, введите имя ', trigger: 'blur' },
    ],
    surname: [
        { required: true, message: 'Пожалуйста, введите фамилия ', trigger: 'blur' },
    ],
    phone: [
        { required: true, message: 'Пожалуйста, введите телефон ', trigger: 'blur' },
    ],
    born_date: [
        { required: true, message: 'Пожалуйста, введите дата рождения ', trigger: 'blur' },
    ],
    gender: [
        { required: true, message: 'Пожалуйста, выберите пол', trigger: 'blur' },
    ],
};
