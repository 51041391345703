import { sort } from "../properties/sort";
import { filter } from "./properties/filter";
import { pagination } from "../properties/pagination";
import { model } from "./properties/model";
import { i18n } from '@/utils/i18n';

export const mutations = {
    SET_LIST: (state, documentTypes) => (state.list = documentTypes),
    SET_ALL: (state, list) => (state.all = list),
    SET_FILTER: (state, filter) => (state.filter = filter),

    SET_PAGINATION: (state, pagination) => (state.pagination = pagination),
    SET_SORT: (state, sort) => (state.sort = sort),
    SET_MODEL: (state, model) => {
        state.model.id = model.id;
        state.model.name = model.name
        state.model.header_template = model.header_template
        state.model.content = model.content
        state.model.category_id = model.category_id
        state.model.footer_template = model.footer_template
        state.model.hide_header = model.hide_header
        state.model.hide_footer = model.hide_footer
        state.model.created_at = model.created_at;
        state.model.updated_at = model.updated_at;
    },
    UPDATE_COLUMN: (state, obj) => {
        state.columns[obj.key].show = obj.value
    },
    UPDATE_SORT: (state, sort) => {
        state.sort[sort.column] = sort.order
    },
    UPDATE_PAGINATION: (state, pagination) => {
        state.pagination[pagination.key] = pagination.value
    },
    EMPTY_MODEL: (state) => {
        state.model = JSON.parse(JSON.stringify(model));
    },
    REFRESH: (state) => {
        state.filter = JSON.parse(JSON.stringify(filter));
        state.sort = JSON.parse(JSON.stringify(sort));
        state.pagination = JSON.parse(JSON.stringify(pagination));
    },
    UPDATE_LANG_COLUMN: (state) => {
        state.columns.id.title = "№";
        state.columns.name.title = i18n.t('message.name');
        state.columns.category_id.title = i18n.t('message.category');
        state.columns.created_at.title = i18n.t('message.date');
        state.columns.updated_at.title = i18n.t('message.update_date');
        state.columns.settings.title = i18n.t('message.settings');
    }
};