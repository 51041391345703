import { i18n } from '@/utils/i18n';

export const columns = {
  id: {
    show: true,
    title: '№',
    sortable: true,
    column: 'id'
  },
  name: {
    show: true,
    title: i18n.t('message.name'),
    sortable: true,
    column: 'name'
  },
  code: {
    show: true,
    title: i18n.t('message.code'),
    sortable: true,
    column: 'code'
  },
  sku: {
    show: true,
    title: i18n.t('message.sku'),
    sortable: true,
    column: 'sku'
  },
  quantity: {
    show: true,
    title: i18n.t('message.quantity'),
    sortable: true,
    column: 'quantity'
  },
  price: {
    show: true,
    title: i18n.t('message.price2'),
    sortable: true,
    column: 'price'
  },
  package_quantity: {
    show: true,
    title: i18n.t('message.package_quantity'),
    sortable: true,
    column: 'price'
  },
  once_price: {
    show: true,
    title: i18n.t('message.once_price'),
    sortable: true,
    column: 'price'
  },
  currency: {
    show: true,
    title: i18n.t('message.currency'),
    sortable: true,
    column: 'currency_id'
  },
  category: {
    show: true,
    title: i18n.t('message.group'),
    sortable: true,
    column: 'category_id'
  },
  vat: {
    show: true,
    title: i18n.t('message.nds'),
    sortable: true,
    column: 'vat'
  },
  description: {
    show: true,
    title: i18n.t('message.description'),
    sortable: true,
    column: 'description'
  },
  measurement: {
    show: true,
    title: i18n.t('message.measurement_full'),
    sortable: true,
    column: 'measurement_id'
  },
  created_at: {
    show: true,
    title: i18n.t('message.date'),
    sortable: true,
    column: 'created_at'
  },
  updated_at: {
    show: false,
    title: i18n.t('message.update_date'),
    sortable: true,
    column: 'updated_at'
  },
  settings: {
    show: true,
    title: i18n.t('message.settings'),
    sortable: false,
    column: 'settings'
  }
};