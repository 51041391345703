import { actions } from './hospitalRoom/actions'
import { getters } from './hospitalRoom/getters'
import { state } from './hospitalRoom/state'
import { mutations } from './hospitalRoom/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}
