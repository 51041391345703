<template>
  <div class="chart-card">
    <div class="chart-card__header">
      <h2>{{ title }}</h2>
      <div>
        <el-date-picker v-model="value1" type="daterange" :start-placeholder="$t('message.start_period')"
                        :end-placeholder="$t('message.end_period')" v-if="selected_item === 'period'">
        </el-date-picker>
      </div>
      <el-dropdown>
        <span class="el-dropdown-link">
          {{ $t(`message.${selected_item}`) }}
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span> 
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="item in dateItems" @click.native="selected_item = item" :key="item">{{
            $t(`message.${item}`) }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <div class="px-3">
      <div class="tableFixHead">
        <table class="table table-hover" v-loading="false">
          <thead>
          <th>
            Врач
          </th>

          <th>
            Консультации
          </th>
          <th>
            Стационар
          </th>
          <th>
            Дневной стационар
          </th>
          <th>
            Сумма
          </th>
          </thead>
          <tbody>
            <tr v-for="(data, doctorId) in reports[selected_item]" :key="doctorId">
              <td class="d-flex align-items-center">
                <el-avatar size="medium" :src="`/img/doctor_icons/2.png`"></el-avatar>
                <p class="m-0 pl-3">{{ data.fullname }}</p>
              </td>
              <td>{{ data.statistic.consultations_count | formatMoney }}</td>
              <td>{{ data.statistic.hospital_count | formatMoney }}</td>
              <td>{{ data.statistic.day_hospital_count | formatMoney }}</td>
              <td>
                <p class="m-0">{{ totalIncome(data) | formatMoney }} сум</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { i18n } from "@/utils/i18n"
import { formatMoney } from "@/filters"
import axios from "axios";
import store from "@/store";
export default {
  props: ['title', 'reports'],
  data() {
    return {
      show: false,
      selected_item: "daily",
      value1: null,
      dateItems: [
        "daily",
        "weekly",
        "monthly",
        "quarterly",
        "half_yearly",
        "yearly",
        "period"
      ],
      backendUrl: process.env.VUE_APP_URL,
      custom_period_value: null,
    }
  },
  computed: {
    ...mapGetters({
      mode: 'theme/MODE',
      token: 'auth/token',
    }),
    totalIncome() {
      return function (data) {
        return data.statistic.consultations_income + data.statistic.hospital_income + data.statistic.day_hospital_income;
      }
    }
  },
  watch: {
    data: function (val) {
      this.setData(val);
    },
    value1: function (val) {
      if (val) {
        this.custom_period_value = val;
        this.selected_item = "period";
        this.fetchData()
            .then((customValues) => {
              this.$set(this.reports, 'period', customValues.data);
            });
      }
    },
    selected_item: function (val) {
      if (val === 'period') {
        this.value1 = this.custom_period_value;
      }
    },
  },
  methods: {
    formatMoney,
    async fetchData() {
      try {
        const response = await axios.get(this.backendUrl + 'dashboard/doctors/custom-period', {
          params: {
            start_date: this.value1[0],
            end_date: this.value1[1],
          },
          headers: {
            'Authorization': 'Bearer ' + this.token,
          },
        });
        return response.data;
      } catch (error) {
        console.error('Ошибка при выполнении запроса:', error);
        throw error; // Пробрасываем ошибку дальше для обработки в вызывающем коде
      }
    },
    setData(val) {
      this.show = false;
    },
  },
}
</script>

<style lang="scss" scoped>
body.dark-mode th {
  background: #3e4166
}

.tableFixHead {
  overflow-y: auto;
  height: 300px;
}

.tableFixHead thead th {
  position: sticky;
  top: 0px;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th {
  background: #eee;
}

th,
td,
table {
  font-size: 14px;
  border: none !important;
  color: #444;

}

thead {
  border-bottom: 1px #44444417 solid;
}

td {
  p {
    font-weight: 600;
  }
}
</style>