import request from '../utils/request'


export function index(params) {
  	return request({
  	  url: '/ReportLimit',
  	  method: 'get',
        params
  	})
  }

  export function store(params) {
    return request({
      url: '/ReportLimit',
      method: 'post',
      params
    })
}
