import {sort} from "../properties/sort";
import {filter} from "./properties/filter";
import {pagination} from "../properties/pagination";
import {model} from "./properties/model";
import { i18n } from '@/utils/i18n';

export const mutations = {
    SET_LIST: (state, costTransactions) => (state.list = costTransactions),
    SET_FILTER: (state,filter) => (state.filter = filter),
    SET_PAGINATION: (state,pagination) => (state.pagination = pagination),
    SET_SORT: (state,sort) => (state.sort = sort),
    SET_MODEL: (state,model) => {
        state.model.id = model.id;
        state.model.payment_type_id = model.payment_type_id;
        state.model.price = parseFloat(model.price);
        state.model.currency_id = model.currency_id;
        state.model.rate = model.rate;
        state.model.date = model.date;
        state.model.comment = model.comment;
        state.model.debit = model.debit;
        state.model.transactionable_id = model.transactionable_id;
        state.model.transactionable_type = model.transactionable_type;
        state.model.transactionable = model.transactionable;
        state.model.created_at = model.created_at;
        state.model.updated_at = model.updated_at;
    },
    UPDATE_COLUMN: (state, obj) => {
        state.columns[obj.key].show = obj.value
    },
    UPDATE_SORT: (state, sort) => {
        state.sort[sort.column] = sort.order
    },
    UPDATE_PAGINATION: (state, pagination) => {
        state.pagination[pagination.key] = pagination.value
    },
    EMPTY_MODEL: (state) => {
        state.model = JSON.parse( JSON.stringify( model ));
    },
    REFRESH: (state) => {
        state.filter = JSON.parse( JSON.stringify( filter ));
        state.sort = JSON.parse( JSON.stringify( sort ));
        state.pagination = JSON.parse( JSON.stringify( pagination ));
    },
    UPDATE_LANG_COLUMN: (state) => {
        state.columns.id.title = "№";
        state.columns.transactionable_id.title = i18n.t('message.type_expence');
        state.columns.payment_type_id.title = i18n.t('message.payment_type');
        state.columns.price.title = i18n.t('message.amount');
        state.columns.currency_id.title = i18n.t('message.currency');
        state.columns.rate.title = i18n.t('message.rate');
        state.columns.date.title = i18n.t('message.date_0');
        state.columns.comment.title = i18n.t('message.comment');
        state.columns.user_id.title = i18n.t('message.user');
        state.columns.created_at.title = i18n.t('message.date');
        state.columns.updated_at.title = i18n.t('message.update_date');
        state.columns.settings.title = i18n.t('message.settings');
    }
};
