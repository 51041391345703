import { actions } from './materials/actions'
import { getters } from './materials/getters'
import { state } from './materials/state'
import { mutations } from './materials/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}
