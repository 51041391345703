import { columns } from './properties/columns';
import { filter } from './properties/filter';
import { model } from './properties/model';
import { rules } from './properties/rules';
import { sort } from './../properties/sort';
import { pagination } from './../properties/pagination';

export const state = {
  list: [],
  inventory: [],
  model: JSON.parse(JSON.stringify(model)),
  modell: [],
  total_amount: '',
  patient_services: [],
  columns: columns,
  filter: JSON.parse(JSON.stringify(filter)),
  pagination: JSON.parse(JSON.stringify(pagination)),
  sort: JSON.parse(JSON.stringify(sort)),
  rules: rules
};