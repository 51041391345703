export const rules = {
    // index: [
    //     { required: true, message: 'Пожалуйста, введите Index ', trigger: 'blur' },
    // ],
    // products: [
    //     { required: true, message: 'Пожалуйста, введите лекарства', trigger: 'blur' },
    // ],
    // dailyTreatmentNames: [
    //     { required: true, message: 'Пожалуйста, введите лекарства ', trigger: 'blur' },
    // ]
};
