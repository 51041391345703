import { actions } from './form_logo/actions'
import { getters } from './form_logo/getters'
import { state } from './form_logo/state'
import { mutations } from './form_logo/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}
