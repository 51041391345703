/* 
1.  Create an object 'drawer' which contains the list of drawer objects. Each drawer object has 2 keys (name and status);
    obj-name == drawer.[obj-name].name
    drawer:{
        create:{
          name:'create',
          status: false
        },
        update:{
          name:'create',
          status: false
        },
      }

2.  Give :visible.sync = drawer.[obj-name].status and :ref = drawer.[obj-name].name of el-drawer ui element
    :visible.sync="drawer.create.status" :ref="drawer.create.name"
3.  Give :drawer-name="drawer.[obj-name].name" to child component of el-drawer 
    <el-drawer ...>:drawer-name
      <childComponent ="drawer.create.name" />
    </el-drawer>
4.  In child component use drawer-child mixin and close() method
*/
export default {
  data() {
    return {

    }
  },
  methods: {
    closeModal(drawer) {
      this.$refs[this.drawer[drawer].name].closeDrawer();
    },
    drawerOpened(ref) {
      this.$refs[ref].opened();
    },
    drawerClosed(ref) {
      this.$refs[ref].closed();
      if (this.reloadList === true) {
        this.fetchData();
        this.afterFetchData();
      }
    },
  },
}