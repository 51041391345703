<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t("message.add_partner_doctor") }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                :loading="loadingButton"
                :disabled="loadingButton ? true : false"
                @click="submit"
                type="success"
                >{{ $t("message.save_and_close") }}</el-button
              >
              <el-button @click="resetForm('form')">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <el-row>
      <el-col :span="24">
        <div class="grid-content">
          <div class="add_patient">
            <el-form ref="form" :model="form" :rules="rules" class="aticler_m">
              <el-row :gutter="24">
                <el-col :span="24">
                  <el-form-item :label="columns.name.title" prop="name">
                    <el-input v-model="form.name" autocomplete="off"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :sm="12">
                  <el-form-item
                    :label="columns.phone_number.title"
                    prop="phone_number"
                  >
                    <crm-input
                      v-model="form.phone_number"
                      :maskFormat="'tel'"
                      autocomplete="off"
                      size="medium"
                    ></crm-input>
                  </el-form-item>
                </el-col>

                <el-col :sm="12">
                  <el-form-item
                    :label="columns.partner_clinic_id.title"
                    prop="partner_clinic"
                  >
                    <el-select
                      :placeholder="$t('message.class')"
                      v-model="form.partner_clinic_id"
                    >
                      <el-option
                        v-for="item in clinics"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                        size="medium"
                      >
                      </el-option>
                    </el-select>

                    <!-- <el-input
              v-model="form.partner_clinic_id"
              autocomplete="off"
            ></el-input> -->
                  </el-form-item>
                </el-col>

                <!-- end col -->
              </el-row>
              <!-- <div class="block">
                <el-tree
                  class="filter-tree"
                  :data="data"
                  show-checkbox
                  node-key="id"
                  :filter-node-method="filterNode"
                  ref="items"
                  @node-click="handleNodeClick"
                  :expand-on-click-node="true"
                >
                  <span
                    :style="`background: ${data.color}`"
                    class="custom-tree-node"
                    slot-scope="{ node, data }"
                  >
                    <span class="aaa my-tree-khan">
                      <div>{{ data.name }}</div>
                      <el-select
                        :placeholder="$t('message.class')"
                        v-model="data.type"
                        :disabled="!node.checked"
                        size="small"
                        class="my-kh ml-3"
                        style=""
                      >
                        <el-option
                          v-for="item in classes"
                          :key="item.name"
                          :label="item.name"
                          :value="item.type"
                        >
                        </el-option>
                      </el-select>
                      <crm-input
                        :disabled="!data.type"
                        v-model="data.amount"
                        :placeholder="'sum'"
                        ref="'data_amount'+ node.id"
                        class="my-kh"
                        size="small"
                      ></crm-input>
                    </span>
                  </span>
                </el-tree>
              </div> -->
              <!-- end row -->
            </el-form>
          </div>
        </div>
      </el-col>
      <!-- end el-col -->
    </el-row>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      loadingButton: false,
      form: {},
      classes: [
        {
          type: "percent",
          name: "Процент",
        },
        {
          type: "amount",
          name: "Сумма",
        },
      ],
      data:[],
      services:[]
    };
  },
  created() {
    this.form = JSON.parse(JSON.stringify(this.model));
  },
   mounted() {
    const query = { relation: true };
    this.servicesRealtionList(query)
      .then((res) => {
        this.data = res.data.services;
      })
      .catch((err) => {});

    if (this.clinics && this.clinics.length == 0) {
      this.loadClinics();
    }
  },
  computed: {
    ...mapGetters({
      rules: "partnerDoctor/rules",
      clinics: "partnerClinic/list",
      model: "partnerDoctor/model",
      columns: "partnerDoctor/columns",
    }),
  },
  methods: {
    ...mapActions({
      servicesRealtionList: "services/relationList",
      save: "partnerDoctor/store",
      selectedPartner: "partnerDoctor/selectedPartnerDoctor",
    }),
    filterNode(value, data) {
      if (!value) return true;
      let dd = data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
      return dd;
    },
    handleNodeClick(node, data, value) {
      this.services = $.grep(this.services, function (e) {
        return e.id != data.data.id;
      });
      this.services.push(data.data);
    },
    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.form.services = this.services;
          this.save(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.saveSelectedPartner(res.data);
              this.form = {};
              this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
              if (close) {
                this.$refs["form"].resetFields();
                this.$emit("c-close", {
                  reload: true,
                  drawer: "drawerPartner",
                });
              }
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$notify({
        title: 'Ошибка',
        type: "error",
        offset: 130,
        message: err.error.message
      });
            });
        }
      });
    },
    saveSelectedPartner(model) {
      this.selectedPartner(model)
        .then((res) => {})
        .catch((err) => {
          this.$notify({
        title: 'Ошибка',
        type: "error",
        offset: 130,
        message: err.error.message
      });
        });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$emit("c-close", { reload: false, drawer: "drawerPartnerDoctor" });
    },
  },
};
</script>