import { actions } from './hospitalization_service/actions'
import { getters } from './hospitalization_service/getters'
import { state } from './hospitalization_service/state'
import { mutations } from './hospitalization_service/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}
