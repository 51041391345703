export const filter = {
    search: '',
    id : null,
    name: '',
    surname: '',
    patronymic : '',
    phone: '',
    last_login: '',
    start_date: '',
    end_date: '',
};