import request from './../utils/request'

export function getOwnerClinic(params) {
    return request({
        url: '/getOwnerClinic/getOwner',
        method: 'get',
        params
    })
}

export function store(data) {
    return request({
        url: '/ownerClinic',
        method: 'post',
        data
    })
}

export function storeConclusionLogo(data) {
    return request({
        url: '/storeConclusionLogo',
        method: 'post',
        data
    })
}

export function update(params) {
    return request({
        url: `/ownerClinic/${params.id}`,
        method: 'post',
        data: params.data
    })
}

export function updateConclusionLogo(params) {
    return request({
        url: `/updateConclusionLogo/${params.id}`,
        method: 'post',
        data: params.data
    })
}

export function destroy(id) {
    return request({
        url: `/ownerClinic/${id}`,
        method: 'delete',
    })
}

export function destroyConclusionLogo(id) {
    return request({
        url: `/destroyConclusionLogo/${id}`,
        method: 'delete',
    })
}