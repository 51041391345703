import Layout from '@/layouts'

const payment = {
  path: '/payments',
  component: Layout,
  name: 'payments',
  redirect: '/payments/index',
  children: [
    {
      path: '/payments/index',
      component: () => import('@/views/payment/index'),
      name: 'payments.index',
      meta: { title: 'Типы расхода'}
    },
    {
      path: '/payments/report',
      component: () => import('@/views/payment/report'),
      name: 'payments.report',
      meta: { title: 'Отчет'}
    },
  ]
}

export default payment