import Layout from '@/layouts'

const settingsRouter = {
  path: '/settings',
  component: Layout,
  name: 'settings',
  redirect: 'settings.index',
  children: [
    {
      path: '/settings/filial',
      component: () => import('@/views/settings/filial'),
      name: 'filial',
      meta: {
        title: 'Филиалы'
      }
    },
    {
      path: '/settings/documentation',
      component: () => import('@/views/settings/documentation'),
      name: 'documentation',
      meta: {
        title: 'Документы'
      }
    },
    {
      path: '/settings/documentationEditor',
      component: () => import('@/views/settings/documentationEditor'),
      name: 'documentationEditor',
      meta: {
        title: 'Документы editor'
      }
    },
    // {
    //   path: '/settings/documentations/index',
    //   component: () => import('@/views/settings/documentations/index'),
    //   name: 'documentationList',
    //   meta: {
    //     title: 'Документы editor'
    //   }
    // },
    {
      path: '/settings/components',
      component: () => import('@/views/settings/comps/index'),
      name: 'components',
      meta: {
        title: 'Документы editor'
      }
    },
    // {
    //   path: '/settings/categoryFormTemplates/index',
    //   component: () => import('@/views/settings/categoryFormTemplates/index'),
    //   name: 'categoryFormTemplates',
    //   meta: {
    //     title: 'Документы'
    //   }
    // },
    {
      path: '/settings/components/createForm',
      component: () => import('@/views/settings/components/createForm'),
      name: 'createForm',
      meta: {
        title: 'Документы2'
      }
    }
  ]
}
export default settingsRouter