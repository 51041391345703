import { i18n } from '@/utils/i18n';
export const expired_columns = {
    
    order_id: {
        show: true,
        title: i18n.t('message.order_id'),
        sortable: true,
        column: 'order_id'
    },

    patient_id: {
        show: true,
        title: i18n.t('message.patient_id'),
        sortable: true,
        column: 'patient_id'
    },

    patient: {
        show: true,
        title: i18n.t('message.patient'),
        sortable: true,
        column: 'patient'
    },

    service: {
        show: true,
        title: i18n.t('message.service'),
        sortable: true,
        column: 'service'
    },

    lifetime: {
        show: true,
        title: i18n.t('message.lifetime'),
        sortable: true,
        column: 'lifetime'
    },
    condition: {
        show: true,
        title: i18n.t('message.condition'),
        sortable: true,
        column: 'condition'
    },

    order_created_at: {
        show: true,
        title: i18n.t('message.order_created_at'),
        sortable: true,
        column: 'order_created_at'
    },

    order_updated_at: {
        show: true,
        title: i18n.t('message.order_updated_at'),
        sortable: true,
        column: 'order_updated_at'
    },  
    settings: {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    }
};