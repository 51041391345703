import Vue from "vue"
/**
 * Element UI
 */
import ru from "element-ui/lib/locale/lang/ru-RU"
import uz from "element-ui/lib/locale/lang/uz-UZ"
import "element-ui/lib/theme-chalk/index.css"

function checkLang() {
  const lang = localStorage.getItem("currentLang")

  if (lang == "uz" || lang == "uz-latin") return uz
  if (lang == "ru") return ru

  return ru
}
const loadPlugins = async () => {
  const ElementUI = await import("element-ui")
  Vue.use(ElementUI, { locale: checkLang() })
}

loadPlugins()

/**
 * end element ui
 */
/**
 * Vue moment
 */
import moment from "moment"
import "moment/locale/uz"

Vue.use(require("vue-moment"), {
  moment,
})

/**
 * money format
 */
import money from "v-money"
Vue.use(money, { precision: 2 })

/**
 * For export excel
 */
import excel from "vue-excel-export"
Vue.use(excel)

/**
 * For alert
 */
import { notify } from "./index"
Vue.prototype.$alert = notify

/**
 * For cursor
 */
import { loadingCursor } from "./index"
Vue.prototype.$loadingCursor = loadingCursor

import { mapGetters } from "vuex"
/**
    Gloabl mixin
 */
Vue.mixin({
  data() {
    return {
      base_url: process.env.VUE_APP_URL,
      base_url_docs: process.env.VUE_APP_URL_DOCS,
    }
  },
  computed: {
    ...mapGetters({
      auth_name: "name",
      role: "role",
    }),
  },
  methods: {
    hasPermission: function (value) {
      let temp = false
      const role = this.role
      if (role && role != null && role != undefined && role.permissions) {
        if (value && value instanceof Array && value.length > 0) {
          temp = true
          value.forEach((element) => {
            temp = temp * role.permissions.some((perm) => perm.slug === element)
          })
        } else {
          temp = role.permissions.some((perm) => perm.slug === value)
        }
      }

      console.log("hasPermission", value, temp)
      return temp
    },
  },
})
