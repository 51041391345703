export const rules = {
    rate: [
        { required: false, message: 'Пожалуйста, выберите валюту ', trigger: 'blur' },
    ],
    price: [
        { required: true, message: 'Пожалуйста, сумма ', trigger: 'blur' },
    ],
    payment_type_id: [
        { required: true, message: 'Пожалуйста, выберите тип оплаты', trigger: 'blur' },
    ],
    // currency_id: [
    //     { required: true, message: 'Пожалуйста, выберите валюту', trigger: 'blur' },
    // ]
};
