export const rules = {
    address: [
        { required: true, message: 'Пожалуйста, введите Адрес ', trigger: 'blur' },
    ],
    // web: [
    //     { required: true, message: 'Пожалуйста, введите название веб-сайта ', trigger: 'blur' },
    // ],
    // email: [
    //     { required: true, message: 'Пожалуйста, введите Email почта ', trigger: 'blur' },
    // ],
    number: [
        { required: true, message: 'Пожалуйста, введите Номер телефона ', trigger: 'blur' },
    ],
    fileList: [
        { required: true, message: 'пожалуйста, выберите логотип', trigger: 'blur' },
    ],
};
