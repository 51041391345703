import { actions } from './printlogo/actions'
import { getters } from './printlogo/getters'
import { state } from './printlogo/state'
import { mutations } from './printlogo/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
};
