import request from './../utils/request';

export function index(params) {
  return request({
    url: '/write-offs',
    method: 'GET',
    params
  });
}

export function show(id) {
  return request({
    url: '/write-offs/' + id,
    method: 'GET'
  });
}

export function store(data) {
  return request({
    url: '/write-offs',
    method: 'POST',
    data
  });
}

export function update(data) {
  return request({
    url: '/write-offs/' + data.id,
    method: 'PUT',
    data
  });
}

export function destroy(id) {
  return request({
    url: '/write-offs/' + id,
    method: 'DELETE'
  });
}

export function destroyItem(id) {
  return request({
    url: `/write-offs/item/${id}`,
    method: 'DELETE'
  });
}